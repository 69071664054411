<div class="position-fixed" style="z-index: 20000;" [ngClass]="{'d-none': !loading.loading}">
    <div class="position-absolute min-vh-100 min-vw-100 d-flex" style="background-color: #000000c7;">
        <div class="m-auto">
            <p-progressSpinner [style]="{width: '80px', height: '80px'}" styleClass="custom-spinner" strokeWidth="6" animationDuration=".5s">
            </p-progressSpinner>
        </div>
    </div>
</div>
<p-toast position="top-center" key="tc" [styleClass]="'alert-set'" life="5000"></p-toast>
<ng-container *ngIf="!page">
    <app-nav-bar style="position: fixed;width: 100%;z-index: 10;"></app-nav-bar>
    <router-outlet></router-outlet>
</ng-container>
<ng-container *ngIf="page">
    <h1 class="text-alert">ใช้งานแนวนอน</h1>
</ng-container>

<app-live *ngIf="live"></app-live>