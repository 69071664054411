import { NavServiceService } from './../../../modules/home/service/nav-service.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {

  imgLogo = `assets/images/startPage/logo.png`;
  icon = 'assets/images/icon';
  iHome = `${this.icon}/home.png`;
  iPin = `${this.icon}/pin.png`;
  iCalendar = `${this.icon}/calendar.png`;
  imgMenu = `${this.icon}/list.png`;
  
  constructor(
    private router: Router,
    public navService: NavServiceService,
  ) { }

  ngOnInit(): void {
  }

  onDropdown() {
    let dropdown = document.getElementById('nav-dropdown');
    if (dropdown.classList.contains('show')) {
      dropdown.classList.remove('show');
    } else {
      dropdown.classList.add('show');
    }
  }

  nextPage(path: string) {
    this.router.navigateByUrl(path);
  }

}
