import { LoadingService } from './service/loading.service';
import { Component } from '@angular/core';
import { AppService } from './app.service';
import {MessageService, PrimeNGConfig} from 'primeng/api';
import { AlertService } from './service/alert.service';
import { AlertInterface } from './shareds/interface/alert.interface';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService]
})
export class AppComponent {
  title = 'royal-thainavi';
  winWidth: number = 0;
  winHeight: number = 0
  page: boolean = false;
  live: boolean = true;
  constructor(
    public loading: LoadingService,
    private app: AppService,
    private messageService: MessageService,
    private primengConfig: PrimeNGConfig,
    private alertSer: AlertService,
    )
     {
      this.alertSer.getAlert().subscribe((dataAlert: AlertInterface) => {
        if (!dataAlert) return;
        this.showTopCenter(dataAlert.severity, dataAlert.title, dataAlert.detail);
      });

    this.app.Authorization = localStorage.getItem('mstk');
    if (window.innerWidth != this.winWidth) setInterval(res => {
      if (window.location.pathname == '/live' || window.location.pathname == '/learning') this.live = false;
      else this.live = true;
      if (window.innerWidth == this.winWidth) return;
      this.winWidth = window.innerWidth;
      this.winHeight = window.innerHeight;
      this.functionSetAction(this.winWidth, this.winHeight);
    }, 500);

    if (document.getElementsByTagName('table')[0]) {
      let view = document.getElementsByTagName('table')[0];
      let text = document.createElement('p');
      text.classList.add("text-right");
      text.classList.add("numText");
      text.innerHTML = 'ผู้เข้าชมสะสม';
      view.appendChild(text);
    }
  }

  ngOnInit() {
    this.primengConfig.ripple = true;
  }

  functionSetAction(width: number, height: number) {
    if (width <= 414) { this.live = false; this.page = true; return }
    if (width <= 768 && height >= 1024) { this.live = false; this.page = true; return }
    if (width <= 1024 && height >= 1366) { this.live = false; this.page = true; return }
    else { this.page = false; this.live = true; }
  }

  showTopCenter(severity:'info'|'success'|'warn'|'error', title: string, detail: string ) {
    // , sticky: true
    this.messageService.add({key: 'tc', severity: severity, summary: title, detail: detail});
  }
}
