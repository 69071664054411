import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare let $;
@Component({
  selector: 'app-live',
  templateUrl: './live.component.html',
  styleUrls: ['./live.component.scss']
})
export class LiveComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
  }

  live() {
    this.router.navigate(['/', 'live'])
  }
}
