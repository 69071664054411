let type: string = 'png';
let path: string = 'https://images.thainavy.org/Panorama/'
let img = [
    `${path}p01.${type}`,
    `${path}p02.${type}`,
    `${path}p03.${type}`,
    `${path}p04.${type}`,
    `${path}p05.${type}`,
    `${path}p06.${type}`,
    `${path}p07.${type}`,
    `${path}p08.${type}`,
    `${path}p09.${type}`,
    `${path}p10.${type}`,
    `${path}p11.${type}`,
    `${path}p12.${type}`,
    `${path}p14.${type}`,
    `${path}p15.${type}`,
    `${path}p16.${type}`,
    `${path}p13.${type}`,
    // "assets/images/panorama/01.jpg",
    // "assets/images/panorama/02.jpg",
    // "assets/images/panorama/03.jpg",
    // "assets/images/panorama/04.jpg",
    // "assets/images/panorama/05.jpg",
    // "assets/images/panorama/06.jpg",
    // "assets/images/panorama/07.jpg",
    // "assets/images/panorama/08.jpg",
    // "assets/images/panorama/09.jpg",
];
let position1 = 'position1';
let position2 = 'position2';
let position3 = 'position3';
let position4 = 'position4';
let position5 = 'position5';
let position6 = 'position6';
let position7 = 'position7';
let position8 = 'position8';
let position9 = 'position9';
let position10 = 'position10';
let position11 = 'position11';
let position12 = 'position12';
let position14 = 'position14';
let position15 = 'position15';
let position16 = 'position16';
let position13 = 'position13';

export const scenes = (_this: any) => {
    console.log(img[0]);

    return {
        position1: {
            "id": 1,
            "name": "position1",
            "hfov": 150,
            "yaw": 0,
            "type": "equirectangular",
            "panorama": img[0],
            "hotSpots": [
                {
                    "pitch": -4,
                    "yaw": 90,
                    "type": "scene",
                    "sceneId": position15,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },
                {
                    "pitch": -1,
                    "yaw": -140,
                    "type": "scene",
                    "sceneId": position11,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },
                // {
                //     "pitch": -2,
                //     "yaw": -117,
                //     "type": "scene",
                //     "sceneId": position12,
                //     "createTooltipFunc": _this.viewIcon,
                //     "createTooltipArgs": "iconWalk",
                // },
                {
                    "pitch": -3,
                    "yaw": 115,
                    "type": "scene",
                    "sceneId": position8,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },
                {
                    "pitch": -3,
                    "yaw": 165,
                    "type": "scene",
                    "sceneId": position10,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },

                // custom
                {
                    "id": 0,
                    "pitch": -5,
                    "yaw": -30,
                    "cssClass": "hotspot-title img1",
                    "createTooltipFunc": _this.hotspotCustom,
                    "createTooltipArgs": "โรงเรียนนายเรืออากาศนวมินทกษัตริยาธิราช",
                    "clickHandlerFunc": () => _this.NextPage(12),
                },
                {
                    "id": 0,
                    "pitch": -2,
                    "yaw": 60,
                    "cssClass": "hotspot-title img1",
                    "createTooltipFunc": _this.hotspotCustom,
                    "createTooltipArgs": "คณะวิศวกรรมศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย",
                    "clickHandlerFunc": () => _this.NextPage(11),
                },

            ]
        },
        position2: {
            "id": 1,
            "name": position2,
            "hfov": 150,
            "yaw": 0,
            "type": "equirectangular",
            "panorama": img[1],
            "hotSpots": [
                {
                    "pitch": -2,
                    "yaw": -105,
                    "type": "scene",
                    "sceneId": position8,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },
                {
                    "pitch": -6,
                    "yaw": 135,
                    "type": "scene",
                    "sceneId": position3,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },
                {
                    "pitch": -2,
                    "yaw": -155,
                    "type": "scene",
                    "sceneId": position9,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },
                {
                    "pitch": -4,
                    "yaw": -90,
                    "type": "scene",
                    "sceneId": position15,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },

                // custom
                {
                    "id": 0,
                    "pitch": -5,
                    "yaw": -30,
                    "cssClass": "hotspot-title img1",
                    "createTooltipFunc": _this.hotspotCustom,
                    "createTooltipArgs": "คณะวิศวกรรมศาสตร์ มหาวิทยาลัยมหิดล",
                    "clickHandlerFunc": () => _this.NextPage(17),
                },
                {
                    "id": 0,
                    "pitch": -3,
                    "yaw": 55,
                    "cssClass": "hotspot-title img1",
                    "createTooltipFunc": _this.hotspotCustom,
                    "createTooltipArgs": "มหาวิทยาลัยเทคโนโลยีพระจอมเกล้าพระนครเหนือ",
                    "clickHandlerFunc": () => _this.NextPage(18),
                },
            ]
        },
        position3: {
            "id": 1,
            "name": position3,
            "hfov": 150,
            "yaw": 0,
            "type": "equirectangular",
            "panorama": img[2],
            "hotSpots": [
                {
                    "pitch": -4,
                    "yaw": -90,
                    "type": "scene",
                    "sceneId": position2,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },
                {
                    "pitch": -3,
                    "yaw": 90,
                    "type": "scene",
                    "sceneId": position4,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },
                {
                    "pitch": -3,
                    "yaw": 145,
                    "type": "scene",
                    "sceneId": position9,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },

                // custom
                {
                    "id": 0,
                    "pitch": -5,
                    "yaw": -60,
                    "cssClass": "hotspot-title img1",
                    "createTooltipFunc": _this.hotspotCustom,
                    "createTooltipArgs": "บริษัท แทนเจอรีน จำกัด",
                    "clickHandlerFunc": () => _this.NextPage(21),
                },
                {
                    "id": 0,
                    "pitch": -4,
                    "yaw": 0,
                    "cssClass": "hotspot-title img1",
                    "createTooltipFunc": _this.hotspotCustom,
                    "createTooltipArgs": "บริษัท แบ็คยาร์ด จำกัด",
                    "clickHandlerFunc": () => _this.NextPage(4),
                },
                {
                    "id": 0,
                    "pitch": -4,
                    "yaw": 72,
                    "cssClass": "hotspot-title img1",
                    "createTooltipFunc": _this.hotspotCustom,
                    "createTooltipArgs": "บริษัท แอ็คโคเมท จำกัด",
                    "clickHandlerFunc": () => _this.NextPage(5),
                },
            ]
        },
        position4: {
            "id": 1,
            "name": position4,
            "hfov": 150,
            "yaw": 0,
            "type": "equirectangular",
            "panorama": img[3],
            "hotSpots": [
                {
                    "pitch": -4,
                    "yaw": -90,
                    "type": "scene",
                    "sceneId": position3,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },
                {
                    "pitch": -5,
                    "yaw": 117,
                    "type": "scene",
                    "sceneId": position5,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },
                {
                    "pitch": -3,
                    "yaw": -157,
                    "type": "scene",
                    "sceneId": position9,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },

                // custom
                {
                    "id": 0,
                    "pitch": -5,
                    "yaw": 0,
                    "cssClass": "hotspot-title img1",
                    "createTooltipFunc": _this.hotspotCustom,
                    "createTooltipArgs": "บริษัท เอชจี โรโบติกส์ จำกัด",
                    "clickHandlerFunc": () => _this.NextPage(6),
                },
                {
                    "id": 0,
                    "pitch": -4,
                    "yaw": 72,
                    "cssClass": "hotspot-title img1",
                    "createTooltipFunc": _this.hotspotCustom,
                    "createTooltipArgs": "บริษัท หัวเว่ยเทคโนโลยี่",
                    "clickHandlerFunc": () => _this.NextPage(8),
                },
            ]
        },
        position5: {
            "id": 1,
            "name": position5,
            "hfov": 150,
            "yaw": 0,
            "type": "equirectangular",
            "panorama": img[4],
            "hotSpots": [
                {
                    "pitch": -5,
                    "yaw": -140,
                    "type": "scene",
                    "sceneId": position4,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },
                {
                    "pitch": -4,
                    "yaw": 90,
                    "type": "scene",
                    "sceneId": position16,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },
                {
                    "pitch": -2,
                    "yaw": 105,
                    "type": "scene",
                    "sceneId": position7,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },
                {
                    "pitch": -3,
                    "yaw": 145,
                    "type": "scene",
                    "sceneId": position9,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },

                // custom
                {
                    "id": 0,
                    "pitch": -5,
                    "yaw": -45,
                    "cssClass": "hotspot-title img1",
                    "createTooltipFunc": _this.hotspotCustom,
                    "createTooltipArgs": "บริษัท ไวซ์ไซท์ (ประเทศไทย) จำกัด",
                    "clickHandlerFunc": () => _this.NextPage(3),
                },
                {
                    "id": 0,
                    "pitch": -5,
                    "yaw": 50,
                    "cssClass": "hotspot-title img1",
                    "createTooltipFunc": _this.hotspotCustom,
                    "createTooltipArgs": "บริษัท ไอโนว์ อินฟอร์เมชั่น จำกัด",
                    "clickHandlerFunc": () => _this.NextPage(2),
                },
            ]
        },
        position6: {
            "id": 1,
            "name": position6,
            "hfov": 150,
            "yaw": 0,
            "type": "equirectangular",
            "panorama": img[5],
            "hotSpots": [
                {
                    "pitch": -3,
                    "yaw": -90,
                    "type": "scene",
                    "sceneId": position5,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },
                {
                    "pitch": -5,
                    "yaw": -120,
                    "type": "scene",
                    "sceneId": position7,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },
                {
                    "pitch": -3,
                    "yaw": -167,
                    "type": "scene",
                    "sceneId": position10,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },
                {
                    "pitch": 0,
                    "yaw": 140,
                    "type": "scene",
                    "sceneId": position11,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },
                {
                    "pitch": -3,
                    "yaw": 80,
                    "type": "scene",
                    "sceneId": position14,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                    "targetYaw": 0,
                },
                {
                    "pitch": -3,
                    "yaw": -90,
                    "type": "scene",
                    "sceneId": position16,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                    "targetYaw": 0,
                },

                // custom
                {
                    "id": 0,
                    "pitch": -5,
                    "yaw": -45,
                    "cssClass": "hotspot-title img1",
                    "createTooltipFunc": _this.hotspotCustom,
                    "createTooltipArgs": "บริษัท เอมเมจิน จำกัด",
                    "clickHandlerFunc": () => _this.NextPage(7),
                },
                {
                    "id": 0,
                    "pitch": -5,
                    "yaw": 50,
                    "cssClass": "hotspot-title img1",
                    "createTooltipFunc": _this.hotspotCustom,
                    "createTooltipArgs": "บริษัท อี แอนด์ ซี อินเตอร์เน็ทเวิร์ค จำกัด",
                    "clickHandlerFunc": () => _this.NextPage(10),
                },
            ]
        },
        position7: {
            "id": 1,
            "name": position7,
            "hfov": 150,
            "yaw": 0,
            "type": "equirectangular",
            "panorama": img[6],
            "hotSpots": [
                {
                    "pitch": -5,
                    "yaw": -125,
                    "type": "scene",
                    "sceneId": position6,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },
                {
                    "pitch": -3,
                    "yaw": 110,
                    "type": "scene",
                    "sceneId": position5,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },
                {
                    "pitch": -9,
                    "yaw": 160,
                    "type": "scene",
                    "sceneId": position16,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },

                // custom
                {
                    "id": 0,
                    "pitch": -10,
                    "yaw": 0,
                    "cssClass": "hotspot-title img1",
                    "createTooltipFunc": _this.hotspotCustom,
                    "createTooltipArgs": "กรมอุทกศาสตร์",
                    "clickHandlerFunc": () => _this.NextPage(14),
                },
            ]
        },
        position8: {
            "id": 1,
            "name": position8,
            "hfov": 150,
            "yaw": 0,
            "type": "equirectangular",
            "panorama": img[7],
            "hotSpots": [
                {
                    "pitch": -4,
                    "yaw": -112,
                    "type": "scene",
                    "sceneId": position2,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },
                {
                    "pitch": -5,
                    "yaw": 125,
                    "type": "scene",
                    "sceneId": position1,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },
                {
                    "pitch": -9,
                    "yaw": -165,
                    "type": "scene",
                    "sceneId": position15,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },

                // custom
                {
                    "id": 0,
                    "pitch": -10,
                    "yaw": 15,
                    "cssClass": "hotspot-title img1",
                    "createTooltipFunc": _this.hotspotCustom,
                    "createTooltipArgs": "กรมวิทยาศาสตร์ทหารเรือ",
                    "clickHandlerFunc": () => _this.NextPage(15),
                },
            ]
        },
        position9: {
            "id": 1,
            "name": position9,
            "hfov": 150,
            "yaw": 0,
            "type": "equirectangular",
            "panorama": img[8],
            "hotSpots": [
                {
                    "pitch": -4,
                    "yaw": 112,
                    "type": "scene",
                    "sceneId": position2,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },
                {
                    "pitch": -4,
                    "yaw": 150,
                    "type": "scene",
                    "sceneId": position3,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },
                {
                    "pitch": -4,
                    "yaw": -155,
                    "type": "scene",
                    "sceneId": position4,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },
                {
                    "pitch": -4,
                    "yaw": -115,
                    "type": "scene",
                    "sceneId": position5,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },

                // custom
                {
                    "id": 0,
                    "pitch": -10,
                    "yaw": 0,
                    "cssClass": "hotspot-title img1",
                    "createTooltipFunc": _this.hotspotCustom,
                    "createTooltipArgs": "สำนักงานวิจัยและพัฒนาการทางทหารกองทัพเรือ",
                    "clickHandlerFunc": () => _this.NextPage(16),
                },
            ]
        },
        position10: {
            "id": 1,
            "name": position10,
            "hfov": 150,
            "yaw": 0,
            "type": "equirectangular",
            "panorama": img[9],
            "hotSpots": [
                {
                    "pitch": -4,
                    "yaw": -98,
                    "type": "scene",
                    "sceneId": position1,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },
                {
                    "pitch": -4,
                    "yaw": 95,
                    "type": "scene",
                    "sceneId": position6,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },
                // {
                //     "pitch": -1,
                //     "yaw": -140,
                //     "type": "scene",
                //     "sceneId": position11,
                //     "createTooltipFunc": _this.viewIcon,
                //     "createTooltipArgs": "iconWalk",
                // },
                // {
                //     "pitch": -1,
                //     "yaw": 130,
                //     "type": "scene",
                //     "sceneId": position12,
                //     "createTooltipFunc": _this.viewIcon,
                //     "createTooltipArgs": "iconWalk",
                // },

                // custom
                {
                    "id": 0,
                    "pitch": -7,
                    "yaw": 0,
                    "cssClass": "hotspot-title img1",
                    "createTooltipFunc": _this.hotspotCustom,
                    "createTooltipArgs": "กรมข่าวทหารเรือ",
                    "clickHandlerFunc": () => _this.NextPage(13),
                },
            ]
        }, position11: {
            "id": 1,
            "name": position11,
            "hfov": 150,
            "yaw": 0,
            "type": "equirectangular",
            "panorama": img[10],
            "hotSpots": [
                {
                    "pitch": -3,
                    "yaw": -50,
                    "type": "scene",
                    "sceneId": position1,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },
                {
                    "pitch": -3,
                    "yaw": 50,
                    "type": "scene",
                    "sceneId": position6,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },
                // {
                //     "pitch": -3,
                //     "yaw": 117,
                //     "type": "scene",
                //     "sceneId": position10,
                //     "createTooltipFunc": _this.viewIcon,
                //     "createTooltipArgs": "iconWalk",
                // },
                {
                    "pitch": -2,
                    "yaw": -180,
                    "type": "scene",
                    "sceneId": position12,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },
                {
                    "pitch": -4,
                    "yaw": 92,
                    "type": "scene",
                    "sceneId": position14,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                    "targetYaw": 0,
                },

                // custom
                {
                    "id": 0,
                    "pitch": -6,
                    "yaw": 3,
                    "cssClass": "hotspot-title img1",
                    "createTooltipFunc": _this.hotspotCustom,
                    "createTooltipArgs": "Information",
                    "clickHandlerFunc": () => _this.NextPage(23),
                },
            ]
        },
        position12: {
            "id": 1,
            "name": position12,
            "hfov": 150,
            "yaw": 0,
            "type": "equirectangular",
            "panorama": img[11],
            "hotSpots": [
                {
                    "pitch": -5,
                    "yaw": -180,
                    "type": "scene",
                    "sceneId": position11,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },

                // custom
                {
                    "id": 0,
                    "pitch": 1,
                    "yaw": 0,
                    "cssClass": "hotspot-title img1 none-text",
                    "createTooltipFunc": _this.hotspotCustom,
                    "createTooltipArgs": "",
                    "clickHandlerFunc": () => _this.NextPage(24),
                },
            ]
        },
        position14: {
            "id": 1,
            "name": position14,
            "hfov": 150,
            "yaw": 180,
            "type": "equirectangular",
            "panorama": img[12],
            "hotSpots": [
                {
                    "pitch": -2,
                    "yaw": -180,
                    "type": "scene",
                    "sceneId": position11,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },
                {
                    "pitch": -1,
                    "yaw": -103,
                    "type": "scene",
                    "sceneId": position6,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },

                // custom
                {
                    "id": 0,
                    "pitch": -7,
                    "yaw": 0,
                    "cssClass": "hotspot-title img1 fix-out",
                    "createTooltipFunc": _this.hotspotCustom,
                    "createTooltipArgs": "ออกจากงาน",
                    "clickHandlerFunc": () => _this.NextPage(-14),
                },
            ]
        },
        position15: {
            "id": 1,
            "name": position15,
            "hfov": 150,
            "yaw": 0,
            "type": "equirectangular",
            "panorama": img[13],
            "hotSpots": [
                {
                    "pitch": -4,
                    "yaw": -90,
                    "type": "scene",
                    "sceneId": position1,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },
                {
                    "pitch": -4,
                    "yaw": 90,
                    "type": "scene",
                    "sceneId": position2,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },
                {
                    "pitch": -10,
                    "yaw": 200,
                    "type": "scene",
                    "sceneId": position8,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },

                // custom
                {
                    "id": 0,
                    "pitch": -4,
                    "yaw": 30,
                    "cssClass": "hotspot-title img1",
                    "createTooltipFunc": _this.hotspotCustom,
                    "createTooltipArgs": "ศูนย์วิจัยและพัฒนาเทคโนโลยีเพื่ออุตสาหกรรม จุฬาลงกรณ์มหาวิทยาลัย",
                    "clickHandlerFunc": () => _this.NextPage(22),
                },
            ]
        },
        position16: {
            "id": 1,
            "name": position16,
            "hfov": 150,
            "yaw": 0,
            "type": "equirectangular",
            "panorama": img[14],
            "hotSpots": [
                {
                    "pitch": -4,
                    "yaw": -90,
                    "type": "scene",
                    "sceneId": position5,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },
                {
                    "pitch": -4,
                    "yaw": 90,
                    "type": "scene",
                    "sceneId": position6,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },
                {
                    "pitch": -9,
                    "yaw": 150,
                    "type": "scene",
                    "sceneId": position7,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },

                // custom
                {
                    "id": 0,
                    "pitch": -7,
                    "yaw": 10,
                    "cssClass": "hotspot-title img1",
                    "createTooltipFunc": _this.hotspotCustom,
                    "createTooltipArgs": "บริษัท ไอแอพพ์เทคโนโลยี จำกัด",
                    "clickHandlerFunc": () => _this.NextPage(1),
                },
            ]
        },
        position13: {
            "id": 1,
            "name": position13,
            "hfov": 150,
            "yaw": 0,
            "type": "equirectangular",
            "panorama": img[15],
            "hotSpots": [
                {
                    "pitch": 0,
                    "yaw": 0,
                    "type": "scene",
                    "sceneId": position14,
                    "createTooltipFunc": _this.viewIcon,
                    "createTooltipArgs": "iconWalk",
                },
            ]
        },
    }
}