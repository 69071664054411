<button class="btn btn-light buttont position-absolute btn-menu-custom" (click)="End()" *ngIf="menuOn">
    <img [src]="iconMap" alt="">
    แผนที่งาน
</button>
<div id="container" class="container">
    <div id="map" class="map">
        <img id="imgMap" alt="imgMap">
        <!-- <ng-container *ngFor="let pos of positionData">
            <ng-container *ngIf="!onZone">
                <div class="booth" [ngStyle]="{
                        'top.px': pos.top,
                        'left.px': pos.left
                    }" (click)="moveMap(pos)">
                    <div class="icon-zone">
                        <img [src]="iconEyeWhite" [alt]="pos.img">
                        <div class="icon-text">
                            <p>{{ pos.name }}</p>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container> -->

        <!-- <ng-container *ngFor="let pos of zoneAData">
            <ng-container *ngIf="onZone">
                <div class="booth" [ngStyle]="{
                        'top.px': pos.top,
                        'left.px': pos.left
                    }" (click)="nextPage(pos.img, pos.id)">
                    <div class="icon-custom" [ngClass]="{'walk': 'walk' === pos.img}">
                        <img [src]="('eye' === pos.img) ? iconEye : iconWalk" [alt]="pos.img">
                        <div class="icon-text">
                            <p>{{ pos.name }}</p>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container> -->

        <ng-container *ngFor="let pos of pointPinMapData">
            <div class="booth" [ngStyle]="{
                    'top.px': pos.top,
                    'left.px': pos.left
                }" (click)="nextPage(pos)">
                <div class="icon-custom pin">
                    <img style="cursor: pointer;" [src]="pathIcon + pos.img" [alt]="pos.img">
                    <div class="icon-text">
                        <p>{{ pos.name }}</p>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngFor="let pos of pointMapData">
            <div class="booth" [ngStyle]="{
                    'top.px': pos.top,
                    'left.px': pos.left
                }" (click)="nextPage(pos)">
                <div class="icon-custom">
                    <img style="cursor: pointer;" [src]="pathIcon + pos.img" [alt]="pos.img">
                    <div class="icon-text">
                        <p>{{ pos.name }}</p>
                    </div>
                </div>
            </div>
        </ng-container>

        <!--  *ngIf="onVideo" -->
        <video controls id="video" style="position: absolute;" class="d-none">
            <source id="source" src="assets/images/homePage/zoomZoneA.mp4" onended="End()" type="video/mp4">
        </video>
        <canvas id="canvas" class="position-absolute w-100 h-100 d-none" [ngClass]="{'d-none': !onVideo}"></canvas>
    </div>
</div>