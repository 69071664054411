<div class="container">
    <div class="row">
        <div class="col-12">
            <div class="row">
                <div class="col-12 text-center">
                    <p class="head-title">องค์กรร่วมจัด</p>
                </div>
            </div>
            <ng-container *ngFor="let item of items; let index = index">
                <div class="row">
                    <div class="col-12 text-center">
                        <p class="font-weight-bold mt-4">{{ item.name }}</p>
                    </div>
                </div>
                <div class="row" *ngFor="let dataItem of item.items; let dataIndex = index">
                    <div class="col-12 d-flex">
                        <div class="compo-logo">
                            <ng-container *ngFor="let imgData of dataItem.img; let indexLogo = index">
                                <div class="text-center" [ngClass]="{'mt-4': indexLogo === 1}">
                                    <img class="img-logo" [src]="imgData.src" alt="" [ngClass]="{'fix-size-logo': 'fix-1'=== imgData.class,
                                                     'fix-size-logo2': 'fix-2'=== imgData.class}">
                                </div>
                            </ng-container>
                        </div>
                        <div class="ml-5">
                            <p>{{ dataItem.name }}</p>
                            <ng-container *ngFor="let text of dataItem.text">
                                <p>{{ text }}</p>
                            </ng-container>
                            <p *ngIf="dataItem.email"><span class="text-danger">Email: </span>{{ dataItem.email }}</p>
                            <ng-container *ngIf="dataItem.website">
                                <div class="d-flex">
                                    <div class="">
                                        <p class="text-danger">Website : </p>
                                    </div>
                                    <div class="" style="margin-left: 5px;">
                                        <ng-container *ngFor="let linkWeb of dataItem.website; let indexWeb = index">
                                            <a style="display: block;" [href]="linkWeb">{{ linkWeb }}</a> <span *ngIf="dataItem.websiteText">{{ dataItem.websiteText }}</span>
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>
                            <p *ngIf="dataItem.phone">{{ dataItem.phone }}</p>
                            <p *ngIf="dataItem.website2"><span class="text-danger">Website : </span><a [href]="dataItem.website2">{{ dataItem.website2 }}</a></p>
                            <p *ngIf="dataItem.email2"><span class="text-danger">Email: </span>{{ dataItem.email2 }}</p>
                            <p class="text-primary">Group: {{dataItem.type}}</p>
                            <ng-container *ngIf="dataItem.product">
                                <p class="text-primary">Product and Service: </p>
                                <div class="" style="margin-left: 5px;">
                                    <ng-container *ngFor="let product of dataItem.product; let indexWeb = index">
                                        <p class="text-primary">{{ product }}</p>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-12">
                        <hr>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>