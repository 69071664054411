<br><br><br>
<br><br><br>
<div class="container">
    <div class="row">
        <div class="col-12">
            <p class="test-text">
                {{text}}
            </p>
        </div>
    </div>
</div>

<!-- <app-chat></app-chat> -->