import { Component, OnInit } from '@angular/core';
import { NavServiceService } from '../../service/nav-service.service';

@Component({
  selector: 'app-inquire',
  templateUrl: './inquire.component.html',
  styleUrls: ['./inquire.component.scss']
})
export class InquireComponent implements OnInit {

  constructor(
    private navService: NavServiceService,
  ) { }
  text: any = `
ฝ่ายเลขานุการ คณะกรรมการอำนวยการ การจัดงานนิทรรศการ AI และ Big Data กองทัพเรือ ประจำปี 2564
หน่วย: กองนโยบายและแผน สำนักนโยบายและแผน กรมการสื่อสารและเทคโนโลยีสารสนเทศทหารเรือ
โทร. 02-475-7621 และ 083-187-1683
Email: schumchuen@gmail.com, Line Id: s_chumchuen
นาวาเอก เสรี ฉ่ำชื่น


คณะอนุกรรมการฝ่ายวิชาการ ฯ
หน่วย: กองสนับสนุนไซเบอร์ ศูนย์ไซเบอร์ กรมการสื่อสารและเทคโนโลยีสารสนเทศทหารเรือ
โทร.02-475-7811 และ 081-860-6971
Email: wittayarut@hotmail.com, Line Id: 081-860-6971
นาวาเอก นิธิ วิทยารัฐ


คณะอนุกรรมการฝ่ายจัดนิทรรศการ ฯ
หน่วย: กองปฏิบัติการไซเบอร์ ศูนย์ไซเบอร์ กรมการสื่อสารและเทคโนโลยีสารสนเทศทหารเรือ
โทร.02-475-7889 และ 096-829-6195
Email: kanin.s@navy.mi.th, Line Id: kyai43
นาวาโท คณิน ศิริธรากูล


คณะอนุกรรมการฝ่ายพิธีการและประชาสัมพันธ์ ฯ
หน่วย: กองวิทยุกระจายเสียงและวิทยุโทรทัศน์ สำนักปฏิบัติการ กรมการสื่อสารและเทคโนโลยีสารสนเทศทหารเรือ
โทร.024-757-674 และ 080-569-9625
Email: djjaidee@gmail.com, Line Id: 080-569-9625
เรือเอก มนต์สิทธิ์ สอนใจดี

`;
  ngOnInit(): void {
    this.navService.showNavbar();
  }
}
