<div class="container">
    <div class="row">
        <div class="col-12" style="padding-top: 91px">
            <div class="swiper-container mySwiper">
                <div class="swiper-wrapper">
                    <ng-container *ngFor="let imgdata of img; let index = index">
                        <div class="swiper-slide">
                            <img [id]="'img'+index" [src]="imgdata" alt="" class="w-100">
                        </div>
                    </ng-container>
                </div>
                <div class="swiper-button-next"></div>
                <div class="swiper-button-prev"></div>
                <div class="swiper-pagination"></div>
            </div>
        </div>
    </div>
</div>