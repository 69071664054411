<ng-container *ngIf="navService.displayNavbar">
    <nav>
        <div class="wrapper">
            <div class="logo" style="cursor: pointer; z-index: 1;">
                <img [src]="imgLogo" alt="img login" class="" (click)="nextPage('start-page')">
            </div>
            <div class="list-link">
                <ul class="ul-link">
                    <li class="li-link menu" (click)="onDropdown()">
                        <img id="icon-dropdown" [src]="imgMenu" width="32px">
                    </li>
                    <li class="li-link" (click)="nextPage('start-page')">
                        <img class="icon" [src]="iHome" alt=""> หน้าหลัก
                    </li>
                    <li class="li-link" (click)="nextPage('walk')">
                        <img class="icon" [src]="iPin" alt=""> แผนที่งาน
                    </li>
                    <li class="li-link" (click)="nextPage('schedule')">
                        <img class="icon" [src]="iCalendar" alt=""> กำหนดการ
                    </li>
                    <li class="li-link" (click)="nextPage('joint-organization')">
                        องค์กรร่วมจัด
                    </li>
                    <li class="li-link" (click)="nextPage('inquire')">
                        สอบถามข้อมูล
                    </li>
                    <li class="li-link" (click)="nextPage('learning')">
                        ความรู้
                    </li>
                    <li class="li-link" (click)="nextPage('winner')">
                        กิจกรรม
                    </li>
                </ul>

                <div id="nav-dropdown" class="dropdown-content">
                    <a (click)="nextPage('start-page')"><img class="icon-mini" [src]="iHome" alt="">หน้าหลัก</a>
                    <a (click)="nextPage('walk')"><img class="icon-mini" [src]="iPin" alt="">แผนที่งาน</a>
                    <a (click)="nextPage('schedule')"><img class="icon-mini" [src]="iCalendar" alt="">กำหนดการ</a>
                    <a (click)="nextPage('joint-organization')">องค์กรร่วมจัด</a>
                    <a (click)="nextPage('inquire')">สอบถามข้อมูล</a>
                    <a (click)="nextPage('learning')">ความรู้</a>
                    <a (click)="nextPage('winner')">กิจกรรม</a>
                </div>
            </div>
        </div>
    </nav>
    <div class="nav-block"></div>
</ng-container>