import { NavServiceService } from './../../service/nav-service.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit {
  data = [
    {
      time: '08:30 – 09:10',
      text: ['ผู้ร่วมงานและแขกรับเชิญ ทยอยเดินทางถึงหอประชุมกองทัพเรือ']
    },
    {
      time: '09:15',
      text: ['ผบ.ทร. เดินทางถึงหอประชุมกองทัพเรือ']
    },
    {
      time: '09:30',
      text: ['ผบ.ทร. ประธานพิธีเปิดฯ', 'กล่าวเปิดงานนิทรรศการ AI และ BIG DATA กองทัพเรือ ประจำปี 2564 ณ ห้อง เจ้าพระยา หอประชุมกองทัพเรือ']
    },
    {
      time: '09:30 – 10:30',
      text: ['การบรรยายพิเศษ เรื่อง "เทคโนโลยี AI และ BIG DATA กับการประยุกต์ใช้ในกองทัพเรือ"', 'โดย ศ.ดร. ธนารักษ์ ธีระมั่นคง  นายกสมาคมปัญญาประดิษฐ์ประเทศไทยและอาจารย์สถาบันเทคโนโลยีนานาชาติสิรินธร มหาวิทยาลัยธรรมศาสตร์']
    },
    {
      time: '10:30 – 11:30',
      text: ['ผู้ร่วมงานและแขกรับเชิญ ร่วมชมนิทรรศการฯ']
    },
    {
      time: '12:00 – 13:00',
      text: ['พักรับประทานอาหารกลางวัน']
    },
    {
      time: '13:00 – 16:00',
      text: ['ผู้ร่วมงาน ชมนิทรรศการฯ ช่วงบ่าย']
    },
    {
      time: '13:00 – 16:00',
      text: ['การสัมมนาเชิงปฏิบัติการ เรื่อง "แนวทางการน าเทคโนโลยี AI และ BIG DATA มาประยุกต์ใช้ในกองทัพเรือ"']
    },
    {
      time: '16:30',
      text: ['พิธีปิดการสัมมนาฯ']
    }
  ];


  constructor(
    private navService: NavServiceService,
  ) { }
  text: any = `
  กำหนดการจัดกิจกรรมให้ความรู้ส่งเสริม สนับสนุนการประยุกต์ใชเ้ทคโนโลยี AI และ Big Data แบบออนไลน์

  วันที่อังคารที่ 10 สิงหาคม พ.ศ. 2564

  09.00 น.                         เริ่มเปิดให้เข้าชมงานนิทรรศการ AI และ Big Data กองทัพเรือ ประจำปี ๒๕๖๔ แบบออนไลน์
  09.15 น.                         จก.สสท.ทร. กล่าววัตถุประสงค์ของการจัดงานนิทรรศการฯ
  09.30 น.                         ผบ.ทร. ประธานในพิธีเปิดงานฯ กล่าวเปิดงานนิทรรศการฯ
  09.30 น. - 10.30 น.         การบรรยายแบบออนไลน์ เรื่อง “เทคโนโลยี AI และ Big Data กับการประยุกต์ใช้ในกองทัพเรือ” 
  10.30 น.                         เป็นต้นไป เยี่ยมชมนิทรรศการฯ ผ่านระบบสารเทศออนไลน์เสมือนจริง (Virtual Exhibition)
  13.00 น. – 16.30 น.        การสมัมนาเชิงปฏิบัติการแบบออนไลน์เรื่อง “แนวทางการนำเทคโนโลยี AI และ Big Data 
                                        มาประยุกต์ใช้กองทัพเรื่อ”  โดย ผู้ทรงคุณวุฒิด้าน AI และ Big Data ในและนอก ทร.

 
  วันที่ 11 - 16 สิงหาคม พ.ศ. 2564

  08.00 น.                         เป็นต้นไปเปิดให้เข้าชมงานนิทรรศการ AI และ Big Data กองทัพเรือ ประจำปี ๒๕๖๔ แบบออนไลน์
                                        และเยี่ยมชมนิทรรศการฯ ผ่านระบบสารเทศออนไลน์เสมือนจริง (Virtual Exhibition)

  

`;
  ngOnInit(): void {
    this.navService.showNavbar();
  }
}


