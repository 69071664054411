import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { HttpService } from '../../services/http.service';
declare let $;

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy {
  chat: boolean;
  srChat: any;
  constructor(private app: AppService,
    private http: HttpService,
    private build: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private zone: NgZone) {
    this.formControl();
  }
  boothid: number;
  itemChat: any = [];
  header: string;
  messageBlock: any = [];
  ngOnInit(): void {
    this.http.methodGet(`ms/profanity`).subscribe(res => this.messageBlock = res);

    this.boothid = this.activatedRoute.snapshot.params['id'];
    if (this.boothid) this.header = "ติดต่อสอบถามข้อมูลบูธนี้"
    else this.header = "ติดต่อสอบถามข้อมูล";
    $.connection.hub.url = this.app.urlSignalr;
    this.srChat = $.connection.chatHub;
    this.srChat.client.UpdateChatSingle = (res) => this.zone.run(() => {
      this.itemChat = res;
      setTimeout(() => {
        if (document.getElementsByClassName("box-message")[0]) {
          let height = document.getElementsByClassName("box-message")[0].scrollHeight;
          document.getElementsByClassName("box-message")[0].scrollTop = height;
        }
      }, 500);
    });
    $.connection.hub.qs = { "token": this.app.Authorization }
    $.connection.hub.start().done((err, res) => {
      this.srChat.server.init(this.boothid || '').fail(err => {
        let str = JSON.stringify(err);
        if (str.indexOf("authorized") == -1) this.app.Authorization = "";
      })
    });
    setTimeout(() => {
      if (document.getElementsByClassName("box-message")[0]) {
        let height = document.getElementsByClassName("box-message")[0].scrollHeight;
        document.getElementsByClassName("box-message")[0].scrollTop = height;
      }
    }, 500);
  }

  ngOnDestroy() {
    $.connection.hub.stop()
  }
  //
  formMessage: FormGroup;
  formControl() {
    this.formMessage = this.build.group({
      CustomerName: "",
      PhoneNo: ""
    })
  }

  height: number;
  chats(chat) {
    this.chat = !chat;
    setTimeout(() => {
      if (this.chat) {
        if (window.outerHeight > 700) this.height = window.outerHeight - 265;
        else this.height = window.outerHeight - 165;
        setTimeout(() => {
          let height = document.getElementsByClassName("box-message")[0].scrollHeight;
          document.getElementsByClassName("box-message")[0].scrollTop = height;
        }, 300);
      }
      else this.height = 44;
    }, 300);
  }

  login() {
    this.http.methodPost(`ms/authen/signin`, this.formMessage.value).subscribe(lg => {
      localStorage.setItem('mstk', lg['Token']);
      this.app.Authorization = lg['Token'];
      $.connection.hub.stop()
      setTimeout(() => {
        $.connection.hub.qs = { "token": lg['Token'] }
        $.connection.hub.start().done((err, res) => {
          this.srChat.server.init(this.boothid).fail(err => {
            let str = JSON.stringify(err);
            if (str.indexOf("authorized") == -1) this.app.Authorization = "";
          })
        })
      }, 1000);
    })

  }

  sendMessage(message: any) {
    if (this.messageBlock.find(fn => fn == message.value)) return alert('ไม่สามารถพิมพ์คำนี้ได้');
    this.srChat.server.reply(this.boothid || '', `${message.value}`).fail(err => {
      let str = JSON.stringify(err);
      if (str.indexOf("authorized") == -1) this.app.Authorization = "";
    })
    message.value = "";
    setTimeout(() => {
      let height = document.getElementsByClassName("box-message")[0].scrollHeight;
      document.getElementsByClassName("box-message")[0].scrollTop = height + 1500;
    }, 200);
  }
}
