import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { appUrl } from 'src/app/appUrl';
import { HttpService } from '../../services/http.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @Input() path: string;
  @Output() update = new EventEmitter();
  constructor(private _renderer: Renderer2,
    private build: FormBuilder,
    private http: HttpService,
    private app: AppService,
    private router: Router) { }

  domainKey: string;
  ngOnInit(): void {
    if (window.location.hostname == 'localhost') this.domainKey = "6LfI5NobAAAAAOx1GYSJHxivLvFubS5GNDyG8n-y";
    if (window.location.hostname == 'thainavy.org') this.domainKey = "6LflQNsbAAAAAHzMv1H808TIi0W1U1pe19kSrTTG";
    if (window.location.hostname == 'นิทรรศการกองทัพเรือ.com') this.domainKey = "6LdNO-0bAAAAADX-BTt6ajC4gP6AgR5YsExabUlj";
    if (window.location.hostname == 'xn--12cas5ddh4b9aabe7cxde1dk2a2e7i.com') this.domainKey = "6Lf5Qu0bAAAAAPfDY_qQn3XbXPMrObKRGi6WX5_X";
    this.formControl();
    let script = this._renderer.createElement('script');
    script.defer = true;
    script.async = true;
    script.src = "https://www.google.com/recaptcha/api.js";
    this._renderer.appendChild(document.body, script);

  }

  form: FormGroup
  formControl() {
    this.form = this.build.group({
      // name1: ["", Validators.required],
      // name2: ["", Validators.required],
      CustomerName: ["", Validators.required],
      PhoneNo: ["", Validators.required],
      CustomerIsGovernment: true,
      CustomerRank: "",
      tokenbot: "",
    })
  }

  maxNumber(v) {
    if (v.value.length > 10) { v.value = ""; alert("กรอกได้สูงสุด 10 ตัว"); }
  }

  token: string;
  resolved(token) {
    this.token = token;
  }

  checkUes(key: boolean) {
    this.form.get('CustomerIsGovernment').setValue(key)
  }

  signin() {
    if (this.form.valid == false) return alert('กรุณากรอกข้อมูลให้ครบ');
    this.form.get('tokenbot').setValue(this.token)
    // this.form.get('CustomerName').setValue(`${this.form.value['name1']}  ${this.form.value['name2']}`)
    // delete this.form.value['name1'];
    // delete this.form.value['name2'];
    // if (this.form.value['CustomerIsGovernment'] && !this.form.value['CustomerRank']) return alert('กรุณากรอก ยศ');
    this.http.methodPost(`ms/authen/signin`, this.form.value).subscribe(res => {
      localStorage.setItem('mstk', res['Token']);
      this.app.Authorization = res['Token'];
      if (this.path) this.update.emit(this.path);
      else this.router.navigateByUrl('home/start')
    })
  }

  cancel() {
    this.router.navigate(['/', appUrl.start])
  }

}
