<ng-container *ngIf="!signin">
    <div id="component" class="overflow-hidden w-100" style="background: #fcfcfd; height: 100%;">
        <img id="background1" class="background" [src]="background1" alt="background1" [ngClass]="{'chang': closeDetail}">
    </div>
    <div class="overflow-hidden w-100 h-100 position-absolute" style="top: 0px;opacity: 1" [ngClass]="{'move': closeDetail}">
        <img class="w-100" [src]="background2" alt="background2">
    </div>
    <div class="w-100 h-100 position-absolute overflow-hidden" style="top: 0px;">
        <div id="compo-btn-data" class="compo-btn-data">
            <ng-container *ngFor="let data of btnData">
                <div class="point-btn-data" [ngStyle]="{
                    'top.px': data.topPersen * (setHeight / 100),
                    'left.px': data.leftPersen * (setWidth / 100) + (((setWidth - innerWidth)/2) * (-1)) }">
                    <div class="btn-data" (click)="nextPage('learning/'+data.id)">
                        <img src="assets/IconAction/Info.png" alt="">
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div id="component-btn" class="position-absolute next-component" [ngClass]="{'none-show': !closeDetail, 'show': closeDetail}">
        <button class="btn btn-next" (click)="nextPage('home/start')">เข้างาน</button>
    </div>
    <div class="position-absolute w-100 component" [ngClass]="{'move': closeDetail}" (click)="onCloseDetail()">
        <div class="container">
            <div class="row">
                <div class="col-4 text-center">
                    <img src="../../../../../assets/images/icon/ncit.png" alt="logo" class="main-logo" style="width: 53%">
                </div>
                <div class="col-4 text-center">
                    <img [src]="logo" alt="logo" class="main-logo" style="width: 100%;
                    position: absolute;
                    left: 0px;
                    right: 0px;
                    top: 16px;">
                </div>
                <div class="col-4 text-center">
                    <img src="../../../../../assets/images/icon/pt.png" alt="logo" class="main-logo" style="width: 48%">
                </div>
            </div>
        </div>
        <p class="title text-basic"> กองทัพเรือ ขอเชิญชม</p>
        <p class="title text-basic"> งานนิทรรศการ AI และ Big Data กองทัพเรือ ประจำปี 2564 แบบออนไลน์</p>
        <p class="title text-basic"> ผ่านระบบสารสนเทศนิทรรศการเสมือนจริง (Virtual Exhibition)</p>
        <!-- <p class="date text-basic"> ระหว่างวันที่ 10 - 31 สิงหาคม 2564 นี้</p> -->
        <!-- <p class="date text-basic">วันนี้ ถึง 30 กันยายน 2564</p> -->
        <br>
        <br>
        <br>
        <!-- <p class="detail text-basic">โซนกิจกรรมวันงาน</p> -->
        <p class="detail text-basic">โซนกิจกรรมในงาน</p>
        <p class="detail text-basic">โซนสัมมนาฟังบรรยายสาระต่าง ๆ เกี่ยวกับ AI และ BIG DATA</p>
        <p class="detail text-basic">โซนบูธแสดงนิทรรศการ จากหน่วยงานชั้นนำทั้งภาครัฐและบริษัทเอกชน</p>
        <div class="text-center">
            <button class="btn btn-primary" style="margin-top: 30px;margin-right: 10px;" (click)="nextPage('learning')">
                AI และ BIG DATA คืออะไร
            </button>
            <button class="btn btn-primary" style="margin-top: 30px;" (click)="nextPage('recommended')">
                แนะนำการใช้งาน
            </button>
        </div>
        <div class="time-component mt-0" *ngIf="displayTime">
            <div class="time-custom ml-auto">
                <p class="date-data text-basic">{{ days
                    < 10 ? '0' : '' }}{{ days }}</p> <p class="text text-basic">DAYS</p>
            </div>
            <div class="time-custom">
                <p class="time text-basic">{{ hours
                    < 10 ? '0' : '' }}{{ hours }}</p> <p class="text text-basic">HOURS</p>
            </div>
            <div class="block-text">
                <p>:</p>
            </div>
            <div class="time-custom">
                <p class="time text-basic">{{ minutes
                    < 10 ? '0' : '' }}{{ minutes }}</p> <p class="text text-basic">MINUTES</p>
            </div>
            <div class="block-text">
                <p>:</p>
            </div>
            <div class="time-custom mr-auto">
                <p class="time text-basic">{{ second
                    < 10 ? '0' : '' }}{{ second }}</p> <p class="text text-basic">SECOND</p>
            </div>
        </div>
        <!-- <div class="container con-advert" *ngIf="!displayTime">
            <div class="swiper-container swiperAdvert">
                <div class="swiper-wrapper">
                    <div *ngFor="let imgA of imgAdvert; let index = index" class="swiper-slide">
                        <img [src]="imgA" class="" alt="">
                    </div>
                </div>
            </div>
        </div> -->

        <br><br>
        <div class="w-100 mt-auto">
            <div class="swiper-container mySwiper">
                <div class="swiper-wrapper">
                    <div *ngFor="let icon of iconLogo; let index = index" class="swiper-slide">
                        <!-- <h1 style="color: white">{{index}}</h1> -->
                        <img [src]="icon" class="logo-basic bbg" alt="" [ngClass]="{'logo-fix1': index === 8||index === 11 ||index === 17||index === 20, 'bbg':index === 9 || index === 12 || index === 13 || index === 14 || index === 16 || index === 18 || index === 19 || index === 21 || index === 22 || index === 23 }">
                    </div>
                </div>
            </div>
        </div>
        <br><br>
    </div>
</ng-container>

<!-- <app-login *ngIf="signin" [path]="paths" (update)="nextPage($event)"></app-login> -->