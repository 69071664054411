<ng-contain *ngIf="!(boothId === 24 || boothId === 23)">
    <div class="position-fixed pos-people" style="z-index: 10;">
        <div class="compo-people d-flex">
            <span class="text my-auto">ผู้เข้าชมบูธ</span>
            <!-- <span class="count-people my-auto ml-auto">100.00k</span> test -->
            <span class="count-people my-auto ml-auto">{{ numberService.formNumber(booth?.CountVisitor || 1) }}</span>
            <span class="text-end my-auto">คน</span>
        </div>
        <div class="icon-people"><img class="w-100" src="assets/images/icon/group.png" alt=""></div>
    </div>

    <div class="position-fixed pos-like" style="z-index: 10;">
        <div class="compo-people d-flex">
            <span class="text my-auto">คนถูกใจบูธ</span>
            <span class="count-people my-auto ml-auto">{{ numberService.formNumber(booth?.CountVote || 1) }}</span>
            <span class="text-end my-auto">คน</span>
        </div>
        <div class="icon-people"><img class="w-100" src="assets/images/icon/like.png" alt=""></div>
    </div>

    <div class="position-fixed btn-click-like" style="z-index: 10;" *ngIf="booth && booth.IsVoted === false">
        <div class="compo-people d-flex" (click)="likeBooth()">
            <span class="text my-auto ml-auto">โหวตถูกใจ</span>
            <div class="my-auto mr-auto">
                <img class="icon-like" src="assets/images/icon/like-white.png" alt="">
            </div>
        </div>
    </div>
</ng-contain>

<div class="back-component position-fixed" style="z-index: 10;">
    <button class="btn icon-back" (click)="backPage()">
        กลับไปยังมุมมอง 360°
        <img class="" [src]="iconBack" alt="">
    </button>
</div>

<div id="container" class="container">
    <div id="booth" class="booth">
        <img id="imgBooth" alt="imgBooth" *ngIf="booth" [src]="booth.LogoImage">

        <div class="point position-absolute" *ngIf="booth" [ngStyle]="{'top.px': booth.FaqY * heightS,'left.px': booth.FaqX * widthS }">
            <div class="compo-text text-white d-flex" (click)="openPopup('faq', ProductId)">
                <img class="ml-auto" [src]="pathIcon + 'Chat.png'" alt="">
                <p class="my-auto mr-auto">คำถามที่พบบ่อย</p>
            </div>
        </div>

        <div class="point position-absolute" *ngIf="booth" [ngStyle]="{'top.px': booth.VideoY * heightS,'left.px': booth.VideoX * widthS }">
            <div class="compo-text text-white d-flex" (click)="openPopup('video', ProductId)">
                <img class="ml-auto" [src]="pathIcon + 'VDO.png'" alt="">
                <p class="my-auto mr-auto">วิดีโอหน่วยงาน</p>
            </div>
        </div>

        <ng-container *ngFor="let dataProduct of product; let ProductIndex = index">
            <div class="point position-absolute" [ngStyle]="{'top.px': dataProduct.PositionY * heightS,'left.px': dataProduct.PositionX * widthS }">
                <div class="compo-text text-white d-flex" (click)="openPopup('product', dataProduct.ProductId)">
                    <img class="ml-auto" [src]="pathIcon + 'Info.png'" alt="">
                    <p class="my-auto mr-auto text-product">ผลิตภัณฑ์ {{ ProductIndex + 1 }}</p>
                </div>
            </div>
        </ng-container>

        <div class="point position-absolute" *ngIf="boothId === 24" [ngStyle]="{'top.px': pinStage.PositionY * heightS,'left.px': pinStage.PositionX * widthS }">
            <div class="compo-text text-white d-flex" (click)="openStage()">
                <img class="ml-auto" [src]="pathIcon + 'VDO.png'" alt="">
                <!-- <p class="my-auto mr-auto"></p> -->
            </div>
        </div>
        <div class="point position-absolute" *ngIf="boothId === 23" [ngStyle]="{'top.px': pinInformation.PositionY * heightS,'left.px': pinInformation.PositionX * widthS }">
            <div class="compo-text text-white d-flex" (click)="openInformation()">
                <img class="ml-auto" [src]="pathIcon + 'Info.png'" alt="">
                <!-- <p class="my-auto mr-auto"></p> -->
            </div>
        </div>

    </div>
</div>

<app-faq [setData]="Popup" [boothId]="boothId" *ngIf="PopupType == 'faq'" (status)="closePopup()"></app-faq>
<app-video [setData]="Popup" [linkVideo]="booth.Video" *ngIf="PopupType == 'video'" (popup)="closePopup()"></app-video>
<app-product2 [setData]="Popup" [boothId]="boothId" [productId]="productId" *ngIf="PopupType == 'product'" (status)="closePopup()"></app-product2>
<app-stage *ngIf="displaystage" (status)="closePopup()"></app-stage>
<app-info *ngIf="displayInformation" (status)="closePopup()"></app-info>
<app-chat></app-chat>