import { Product } from './../../interface/product.Interface';
import { LoadingService } from './../../../../service/loading.service';
import { PositionHome } from './../../interface/position.interface';
import { Booth } from './../../interface/booth.interface';
import { BoothService } from './../../../../service/booth.service';
import { NavServiceService } from './../../service/nav-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, HostListener } from '@angular/core';
import { HttpService } from 'src/app/shareds/services/http.service';
import point15 from '../../json/pointBooth15.json';
import point1 from '../../json/pointBooth1.json';
import point10 from '../../json/pointBooth10.json';
import Panzoom from '@panzoom/panzoom';
import { NumberService } from 'src/app/service/number.service';
import { AlertService } from 'src/app/service/alert.service';
import { AlertInterface } from 'src/app/shareds/interface/alert.interface';

@Component({
  selector: 'app-booth',
  templateUrl: './booth.component.html',
  styleUrls: ['./booth.component.scss']
})
export class BoothComponent implements OnInit {

  Popup: boolean = false;
  PopupType: string;
  winWidth: number = 0;
  winheight: number = 0;
  page: boolean = false;
  path: string;
  iconBack = 'assets/images/icon/next.png';
  booth: Booth;
  product: Product[];
  boothId: number = 0;
  productId: number = 0;

  setWidth = 2731;
  setHeight = 1536;
  heightS = 0;
  widthS = 0;

  panzoom: any;
  elementContainer: any;
  elementDivMap: any;
  elementImgMap: any;
  moving = false;
  startPan = false;
  funcOn: any;

  pathBackground = 'assets/images/img360/';
  pathIcon = 'assets/IconAction/';

  point: PositionHome[];

  timeSet: any;

  // fix เวที สอบถาม
  displaystage = false;
  displayInformation = false;
  
  pinStage = {
    img: 'VDO',
    PositionY: 50,
    PositionX: 50,
    top: 50,
    left: 50,
    name: 'test',
  };
  pinInformation = {
    img: 'VDO',
    PositionY: 88.7771296296,
    PositionX: 65.5729166667,
    top: 50,
    left: 50,
    name: 'test',
  };


  constructor(
    private router: Router,
    private atvRouter: ActivatedRoute,
    private navService: NavServiceService,
    private boothService: BoothService,
    private loadingService: LoadingService,
    public numberService: NumberService,
    public alertSer: AlertService,
    ) {
      this.point = point15.items;
      this.getDataPath();
  }

  ngOnInit(): void {
    this.loadingService.changeCount(1);
    this.navService.showNavbar();
    
    // if (window.innerWidth != this.winWidth) setInterval(res => {
    //   if (window.innerWidth == this.winWidth) return;
    //   this.winWidth = window.innerWidth;
    //   this.winheight = window.innerHeight;
    //   this.functionSetAction(this.winWidth, this.winheight);
    // }, 500)
    setTimeout(() => {
      this.loadingService.resetCount();
    }, 500);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.timeSet) return;

    this.timeSet = setTimeout(() => {
      this.setImg();
      this.timeSet = 0;
    }, 500);
  }

  getDataPath() {
    this.path = this.atvRouter.snapshot.paramMap.get("path");
    let dataId = this.atvRouter.snapshot.paramMap.get("id");
    if (dataId) this.boothId = parseInt(dataId);

    this.boothService.findBooth(this.boothId).subscribe(
      (res: Booth) => {
        this.booth = res;
        setTimeout(() => { this.setImg(); }, 500);
      },
      err => {
        let dataAlert: AlertInterface;
        dataAlert = { severity: 'error', title: 'error', detail: err.message }
        this.alertSer.setAlert(dataAlert);
      }
    );
    this.boothService.getProduct(this.boothId).subscribe(
      (res: Product[]) => {
        this.product = res;
        if (res && res.length > 0) {
          this.productId = res[0].ProductId;
        }
      },
      err => {
        let dataAlert: AlertInterface;
        dataAlert = { severity: 'error', title: 'error', detail: err.message }
        this.alertSer.setAlert(dataAlert);
      }
    );
  }

  setImg() {
    this.elementContainer = document.getElementById('container');
    this.elementDivMap = document.getElementById('booth');
    this.elementImgMap = document.getElementById('imgBooth');
     
    let innerHeight = window.innerHeight;
    let innerWidth = window.innerWidth;
    this.elementContainer.style.maxWidth = window.innerWidth + 'px';
    this.elementContainer.style.maxHeight = innerHeight + 'px';
    if (innerHeight != 861) {
      this.setWidth = (innerHeight * this.setWidth) / this.setHeight;
      this.setHeight = innerHeight;
    }
    if (innerWidth > this.setWidth) {
      this.setHeight = (innerWidth * this.setHeight) / this.setWidth;
      this.setWidth = innerWidth;
    }
    this.elementDivMap.style.height = this.setHeight + 'px';
    this.elementDivMap.style.width = this.setWidth + 'px';
    this.elementImgMap.style.height = this.setHeight + 'px';

    this.heightS = this.setHeight / 100;
    this.widthS = this.setWidth / 100;
    for (let item of this.point) {
      item.left = this.widthS * item.leftPersen;
      item.top = this.heightS * item.topPersen;
    }

    let customY = window.innerHeight - this.setHeight;

    this.panzoom = Panzoom(this.elementDivMap, {
      animate: false,
      duration: 700,
      contain: 'outside',
      startY: customY > 0 ? 0: customY,
      maxScale: 1.9,
      minScale: 1,
      startTransform: `scale(1) translate(-15px, 0px)`
    });

    // enable mouse wheel
    const parent = this.elementDivMap.parentElement;
    this.elementDivMap.addEventListener('dblclick', this.panzoom.reset);
    parent.addEventListener('wheel', this.panzoom.zoomWithWheel);

    this.elementDivMap.addEventListener('panzoompan', (event: any) => {
      this.moving = true;
      if (!this.startPan) {
        this.startPan = true;
        this.moving = false;
      }
    });

    this.elementDivMap.addEventListener('panzoomend', (event: any) => {
      if (this.moving) {
        if (this.funcOn) clearTimeout(this.funcOn);
        this.funcOn = setTimeout(() => {
          this.moving = false;
        });
      }
    });

  }

  likeBooth() {
    this.loadingService.changeCount(1);
    this.boothService.putVote(this.boothId).subscribe(
      (res: Booth) => {
        // console.log('res vote: ', res);
        let dataAlert: AlertInterface;
        dataAlert = { severity: 'success', title: 'โหวตสำเร็จ!', detail: '' }
        this.alertSer.setAlert(dataAlert);
        this.booth = res;
        setTimeout(() => {
          this.loadingService.resetCount();
        }, 500);
      },
      err => {
        let dataAlert: AlertInterface;
        dataAlert = { severity: 'error', title: 'โหวตไม่สำเร็จ', detail: err.message }
        this.alertSer.setAlert(dataAlert);
        setTimeout(() => {
          this.loadingService.resetCount();
        }, 500);
      }
    );
  }

  functionSetAction(width: number, height: number) {
    // let bg = document.getElementById("bg");
    // let chat = document.getElementById("chat");
    // let info = document.getElementById("info");
    // let vdo = document.getElementById("vdo");
    // bg.style.height = '100%'
    // 


    let model = {
      vdo: document.getElementById("vdo"),
      info: document.getElementById("info"),
      chat: document.getElementById("chat"),
      bg: document.getElementById("bg"),
      topInfo: 0,
      leftInfo: 0,
      //
      topChat: 0,
      leftChat: 0,
      //
      topVdo: 0,
      leftVdo: 0,

    }

    if (!model.bg) return;
    model.bg.style.height = '100%';


    // ขนาดหน้าจอ มือถือ
    if (width < 812) {
      model.topInfo = 2; model.leftInfo = 7;
      model.topChat = 1.35; model.leftChat = 1.3;
      model.topVdo = 1.5; model.leftVdo = 2.6;
      this.setPosition(model);
      return
    }

    // ขนาดหน้าจอ มือถือ
    if (width <= 823) {
      model.topInfo = 2.2; model.leftInfo = 5.8;
      model.topChat = 1.38; model.leftChat = 1.32;
      model.topVdo = 1.4; model.leftVdo = 2.7;
      this.setPosition(model);
      return
    }

    // ขนาดหน้าจอ ipad
    if (width < 1305) {
      model.topInfo = 2.2; model.leftInfo = 5.5;
      model.topChat = 1.45; model.leftChat = 1.3;
      model.topVdo = 1.5; model.leftVdo = 2.4;
      this.setPosition(model);
      return
    }
    // ขนาดหน้าจอ ipad pro
    if (width <= 1366) {
      model.topInfo = 2.4; model.leftInfo = 5;
      model.topChat = 1.6; model.leftChat = 1.3;
      model.topVdo = 1.7; model.leftVdo = 2.3;
      this.setPosition(model);
      return
    }

    // ขนาดหน้าจอ 
    if (width > 1367) {
      model.topInfo = 2.4; model.leftInfo = 5;
      model.topChat = 1.6; model.leftChat = 1.3;
      model.topVdo = 1.7; model.leftVdo = 2.3;
      this.setPosition(model);
      return
    }

    // ขนาดหน้าจอ 
    if (width >= 1486) {
      model.topInfo = 2.1; model.leftInfo = 4;
      model.topChat = 1.4; model.leftChat = 1.25;
      model.topVdo = 1.7; model.leftVdo = 2.1;
      this.setPosition(model);
      return
    }

  }

  setPosition(model) {
    // ตั้งค่าตำแหน่งสินค้า
    model.info.style.top = `${model.bg.getBoundingClientRect().height / model.topInfo}px`;
    model.info.style.left = `${model.bg.getBoundingClientRect().width / model.leftInfo}px`;
    // ตั้งค่าตำแหน่งแชท
    model.chat.style.top = `${model.bg.getBoundingClientRect().height / model.topChat}px`;
    model.chat.style.left = `${model.bg.getBoundingClientRect().width / model.leftChat}px`;
    // ตั้งค่าตำแหน่งวิดีโอ
    model.vdo.style.top = `${model.bg.getBoundingClientRect().height / model.topVdo}px`;
    model.vdo.style.left = `${model.bg.getBoundingClientRect().width / model.leftVdo}px`;
    // console.log(model.bg.getBoundingClientRect());
  }

  openPopup(key: string, productId: number) {
    this.productId = productId;
    this.PopupType = key;
    this.Popup = true;
  }

  openStage() {
    this.displaystage = true;
  }

  openInformation() {
    this.displayInformation = true;
  }

  closePopup() {
    this.PopupType = "";
    this.Popup = false;

    this.displaystage = false;
    this.displayInformation = false;
  }

  backPage() {
    if (this.path.substr(0, 4) === 'home') {
      let subPath = this.path.split('-');
      this.router.navigateByUrl(`home/${subPath[1]}`);
    } else {
      this.router.navigateByUrl(this.path);
    }
  }

}
