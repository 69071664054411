<div class="container">
    <div class="row">
        <div class="col-12">
            <div class="row">
                <div class="col-12 text-center">
                    <p class="head-title">องค์กรร่วมจัด</p>
                </div>
            </div>
            <ng-container *ngFor="let item of items; let index = index">
                <div class="row">
                    <div class="col-12 text-center">
                        <p class="font-weight-bold mt-4" >{{ item.name }}</p>
                    </div>
                </div>
                <div class="row" *ngFor="let dataItem of item.items; let dataIndex = index">
                    <div class="col-xl-6 d-flex flex-column">
                        <ng-container *ngFor="let imgData of dataItem.img; let indexLogo = index">
                            <div [ngClass]="{'m-auto': indexLogo === 0,
                                             'mt-3 mx-auto mb-auto': indexLogo === 1}">
                                <img class="img-logo" [src]="imgData.src" alt=""
                                     [ngClass]="{'fix-size-logo': 'fix-1'=== imgData.class,
                                                 'fix-size-logo2': 'fix-2'=== imgData.class}">
                            </div>
                        </ng-container>
                    </div>
                    <div class="col-xl-6" style="padding-top: 20px;">
                        <p>{{ dataItem.name }}</p>
                        <ng-container *ngFor="let text of dataItem.text">
                            <p>{{ text }}</p>
                        </ng-container> 
                        <ng-container *ngIf="dataItem.website">
                            <div class="d-flex" style="margin-bottom: 17px">
                                <div class="">
                                    <p>Website : </p>
                                </div>
                                <div class="" style="margin-left: 5px;">
                                    <ng-container *ngFor="let linkWeb of dataItem.website; let indexWeb = index">
                                        <a style="display: block;" [href]="linkWeb">{{ linkWeb }}</a>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>
                        <p *ngIf="dataItem.phone">{{ dataItem.phone }}</p>
                        <p *ngIf="dataItem.website2"><a [href]="dataItem.website2">{{ dataItem.website2 }}</a></p>
                        <p *ngIf="dataItem.email">{{ dataItem.email }}</p>
                    </div>
                    <div class="col-12">
                        <hr>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>