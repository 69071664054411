<p-dialog header="คำถามที่พบบ่อย" [(visible)]="openPopup" [modal]="true" [style]="{width: '90vw'}" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false" (onHide)="onClose()" [styleClass]="'product'" [showHeader]="false">
    <img class="icon-close" [src]="iconClose" alt="icon-close" (click)="onClose()">
    <div class="row">
        <div class="col-xl-8 col-lg-12 col-md-12 col-sm-12">
            <div class="card-custom d-flex" style="flex-direction: column;">
                <p class="title">กรมวิทยาศาสตร์ทหารเรือ</p>
                <div class="detail mx-auto">
                    <div class="row">
                        <div class="col-12">
                            <div class="swiper-container mySwiper">
                                <div class="swiper-wrapper">
                                    <ng-container *ngIf="product">
                                        <div *ngFor="let img of product.Images; let index = index"
                                             class="swiper-slide">
                                            <img [src]="img.Image" alt="">
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="swiper-button-next"></div>
                                <div class="swiper-button-prev"></div>
                                <div class="swiper-pagination"></div>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngFor="let dataDetail of detail">
                        <p>&nbsp;&nbsp;&nbsp;&nbsp; {{ dataDetail }}</p>
                    </ng-container>
                </div>
                <div class="d-block d-xl-none">
                    <p class="title">ยานพิสูจน์ทราบสารพิษ (Unmanned Ground Vehicle(UGV)) "เสือดำ"</p>
                    <div class="position-relative" style="padding: 15px">
                        <ng-container *ngIf="!showVideo">
                            <img class="position-absolute" style="top:50%;left:45%;cursor: pointer;" [src]="iconVideo"
                                alt="" (click)="showVideo = true">
                            <img [src]="logoVideo" width="100%" style="border-radius: 10px;" alt="">
                        </ng-container>
                        <video controls autoplay *ngIf="showVideo" style="width: 100%;height: 250px;">
                            <source src="https://video.kmutnb-inno.top/demo2.mp4" type="video/mp4">
                        </video>

                    </div>
                    <div class="row">
                        <div class="col-md-6 col-12">
                            <div class="text-center btn-operator p-3">
                                <button class="btn btn-custom" (click)="openChat()">
                                    <img [src]="iconChat" alt="">
                                    ดาวน์โหลดโบรชัวร์
                                </button>
                            </div>
                        </div>
                        <div class="col-md-6 col-12">
                            <div class="text-center btn-operator p-3 btn-last">
                                <button class="btn btn-custom" (click)="openChat()">
                                    <img [src]="iconChat" alt="">
                                    แชทสอบถามข้อมูล
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 d-none d-xl-block">
            <div class="card-custom">
                <p class="title">ยานพิสูจน์ทราบสารพิษ (Unmanned Ground Vehicle(UGV)) "เสือดำ"</p>
                <div class="position-relative" style="padding: 15px">
                    <ng-container *ngIf="!showVideo">
                        <img class="position-absolute" style="top:50%;left:45%;cursor: pointer;" [src]="iconVideo"
                            alt="" (click)="showVideo = true">
                        <img [src]="logoVideo" width="100%" style="border-radius: 10px;" alt="">
                    </ng-container>
                    <video controls autoplay *ngIf="showVideo" style="width: 100%;height: 250px;">
                        <source src="http://video.thainavy.org/demo2.mp4" type="video/mp4">
                    </video>
                </div>
            </div>
            <div class="text-center btn-operator">
                <button class="btn btn-custom" (click)="openChat()">
                    <img [src]="iconPdf" alt="">
                    ดาวน์โหลดโบรชัวร์
                </button>
            </div>
            <div class="text-center btn-operator">
                <button class="btn btn-custom" (click)="openChat()">
                    <img [src]="iconChat" alt="">
                    แชทสอบถามข้อมูล
                </button>
            </div>
        </div>
    </div>
</p-dialog>