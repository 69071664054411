import { Product } from './../../../modules/home/interface/product.Interface';
import { BoothService } from './../../../service/booth.service';
import { LoadingService } from './../../../service/loading.service';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { AlertService } from 'src/app/service/alert.service';
import { AlertInterface } from '../../interface/alert.interface';
declare let Swiper;

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  @Output() status = new EventEmitter<boolean>();
  @Input() boothId: number;
  @Input() productId: number;
  @Input() set setData(data: any) {
    if (data) {
      this.loadingService.changeCount(1);
      this.openPopup = true;
      setTimeout(() => {
        this.boothService.findProduct(1).subscribe(
          (res: Product) => {
            this.product = res;
            setTimeout(() => {
              this.setSwiper();
            }, 300);
            this.loadingService.resetCount();
          }, err => {
            let dataAlert: AlertInterface;
            dataAlert = { severity: 'error', title: 'error', detail: err.message }
            this.alertSer.setAlert(dataAlert);
          }
        );
        this.loadingService.resetCount();
      }, 500);
    }
  }
  openPopup = false;
  showVideo = false;
  iconClose = 'assets/images/icon/Close.png'; 
  iconChat = 'assets/images/icon/FAQ.png';
  imgProduct = 'assets/images/vision.jpg'
  logoVideo = 'assets/images/logoPlay.jpg';
  iconVideo = 'assets/IconAction/VDO.png';
  iconPdf = 'assets/images/icon/brochure.png';
  product: Product;

  detail = [
    '-กรมวิทยาศาสตร์ทหารเรือ ได้เริ่มจัดตั้งขึ้นเป็น "หอวิทยาศาสตร์" เมื่อ ๑๘ มิถุนายน พ.ศ.๒๔๗๑ โดยมีภารกิจ คือ การวิเคราะห์ทางเคมี การทดสอบ ทางฟิสิกส์ และการทดลองทางไฟฟ้า',
    '-พ.ศ.๒๔๗๓ หอวิทยาศาสตร์ ได้รับโอนแผนกโรงงานฝึกการจักรของโรงเรียนนายเรือเข้ามา รวมอยู่ด้วย เนื่องจากในขณะนั้น ยังขึ้นการปกครองกับโรงเรียนนายเรือ',
    '-พ.ศ.๒๔๘๖ หอวิทยาศาสตร์ ได้รับสมัครผู้ที่สำเร็จการศึกษาจากมหาวิทยาลัยเข้าเป็นนายทหารสัญญาบัตร และผู้สำเร็จการศึกษามัธยมปีที่ ๖ (ม.๖ เดิม)เข้าเป็นพนักงานประจำหอวิทยาศาสตร์ เป็นครั้งแรก',
    '-พ.ศ.๒๔๙๒ หอวิทยาศาสตร์ ได้เปลี่ยนฐานะเป็น "กองวิทยาศาสตร์ ทหารเรือ" ขึ้นตรงต่อ กรมเสนาธิการทหารเรือ',
    '-พ.ศ.๒๔๙๔ ได้เพิ่มแผนกวิจัยขึ้นมาอีกแผนกหนึ่ง ให้มีภารกิจในการศึกษา ค้นคว้าทดลองและผลิตวัสดุขึ้นมาตามความต้องการของกองทัพเรือ',
    '-พ.ศ.๒๔๙๗ กองทัพเรือได้ดำเนินการก่อสร้างอาคารสำนักงานกองวิทยาศาสตร์ทหารเรือให้ใหม่ โดยอยู่ในบริเวณเยื้องกับสถานีตำรวจนครบาล บางกอกใหญ่ ถนนอรุณอัมรินทร์',
    '-พ.ศ.๒๕๐๑ เปลี่ยนฐานะเป็นหน่วยขึ้นตรงกองทัพเรือ โดยมีพระราชกฤษฎีกายกฐานะกองวิทยาศาสตร์ทหารเรือ ขึ้นเป็น " กรม วิทยาศาสตร์ ทหารเรือ " จัดอยู่ในส่วนกิจการพิเศษ ขึ้นตรงต่อกองทัพเรือ',
    '-พ.ศ.๒๕๒๗ ได้รับอนุมัติให้ปรับปรุงอัตราและจัดส่วนราชการเพิ่มขึ้น โดยแบ่งเป็น ๔ กอง ๓ แผนก คือ กองแผนและโครงการ กองวิเคราะห์ และทดสอบ กองวิทยาการ กองผลิตและซ่อมบำรุง แผนกธุรการ แผนกการเงิน และแผนกคลังวิทยาศาสตร์',
    '-พ.ศ.๒๕๔๐ ได้รับอนุมัติให้ปรับปรุงอัตราและจัดส่วนราชการใหม่โดยแบ่งเป็น ๕ กอง ๑ แผนก คือ กองบังคับการ กองแผนและโครงการ กองวิเคราะและทดสอบ กองวิทยาการ กองผลิตและซ่อมบำรุง และแผนกคลังวิทยาศาสตร์',
    '-๑๘ เม.ย.๔๐ กรมวิทยาศาสตร์ทหารเรือ ย้ายจากที่ตั้งเดิมไปยังที่ตั้งใหม่ เลขที่ ๘๓ หมู่ ๑๒ ถนนพุทธมณฑลสาย ๓ แขวงศาลาธรรมสพน์ เขตทวีวัฒนา กรุงเทพ และได้เริ่มปฏิบัติงานตามปกติ ใน ๒๑ เม.ย.๔๐'
  ];

  constructor(
    private primengConfig: PrimeNGConfig,
    private loadingService: LoadingService,
    private boothService: BoothService,
    private alertSer: AlertService,
    ) {

     }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
  }

  onOpen() {
    this.openPopup = true;
  }

  openChat() {
    window.open('https://page.line.me/211topar');
  }

  setSwiper() {
    let swiper = new Swiper(".mySwiper", {
      spaceBetween: 30,
      speed: 1000,
      loop: true,
      autoplay: {
        delay: 10000,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".swiper-pagination",
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  }

  onClose() {
    this.openPopup = false;
    this.status.emit(false);
  }

}
