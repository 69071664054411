import { Product } from './../../../modules/home/interface/product.Interface';
import { BoothService } from './../../../service/booth.service';
import { LoadingService } from './../../../service/loading.service';
import { PrimeNGConfig } from 'primeng/api';
import { Component, Input, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import Panzoom from '@panzoom/panzoom';
import { environment } from 'src/environments/environment';
import { AlertService } from 'src/app/service/alert.service';
import { AlertInterface } from '../../interface/alert.interface';
declare let Swiper;

@Component({
  selector: 'app-product2',
  templateUrl: './product2.component.html',
  styleUrls: ['./product2.component.scss']
})
export class Product2Component implements OnInit {

  @Output() status = new EventEmitter<boolean>();
  @Input() boothId: number;
  @Input() productId: number;
  @Input() set setData(data: any) {
    if (data) {
      this.loadingService.changeCount(1);
      this.openPopup = true;
      setTimeout(() => {
        this.sourceVDO = document.getElementById('source');
        this.Vdo = document.getElementById('vdo');
        this.boothService.findProduct(this.productId).subscribe(
          (res: Product) => {
            this.product = res;
            if (!this.product.VideoImage || this.product.VideoImage === '') {
              this.showVideo = true;
            }
            if (this.product.Video) {
              this.typeVDO = this.product.Video.includes('video.thainavy.org');
              this.dataVod = this.product.Video;
              if (!this.typeVDO) {
                setTimeout(() => {
                  let iframe: any = document.getElementById('iframe'+this.productId);
                  iframe.src = this.product.Video;
                }, 500);
              }
            }
            if (this.product.PDF) {
              this.product.PDF = `${environment.apiURL}/ms/product/download/doc/${this.productId}`;
            }
            setTimeout(() => {
              this.setSwiper();
            }, 1000);
            this.loadingService.resetCount();
          }, err => {
            let dataAlert: AlertInterface;
            dataAlert = { severity: 'error', title: 'error', detail: err.message }
            this.alertSer.setAlert(dataAlert);
          }
        );
        this.loadingService.resetCount();
      }, 500);
    }
  }

  openPopup = false;
  showVideo = false;
  typeVDO = false;
  product: Product;
  
  iconClose = 'assets/images/icon/Close.png'; 
  iconChat = 'assets/images/icon/FAQ.png';
  imgProduct = 'assets/images/vision.jpg'
  logoVideo = 'assets/images/logoPlay.jpg';
  iconVideo = 'assets/IconAction/VDO.png';
  iconPdf = 'assets/images/icon/brochure.png';

  displayImg = false;
  imgShow = '';
  panzoom: any;

  sourceVDO: any;
  Vdo: any;
  dataVod = 'https://video.kmutnb-inno.top/demo2.mp4';

  moving = false;
  startPan = false;
  funcOn: any;

  constructor(
    private primengConfig: PrimeNGConfig,
    private loadingService: LoadingService,
    private boothService: BoothService,
    private alertSer: AlertService,
  ) {

  }

  ngOnInit(): void {

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.openPopup && this.displayImg) {
      this.panzoom.destroy();
      setTimeout(() => {
        this.setImgShow();
      }, 300);
    }
  }

  setSwiper() {
    if (!this.product.Images) return;
    if (this.product.Images.length === 0) return;

    let countImg = 3;
    let lengthImg = this.product.Images.length; 
    if (this.product && lengthImg < 3) {
      countImg = lengthImg;
    }
    if (window.innerWidth <= 640) {
      countImg = 1;
    } else if (window.innerWidth <= 823 && lengthImg >= 2) {
      countImg = 2;
    }
    let swiper = new Swiper(".mySwiper", {
      slidesPerView: countImg,
      spaceBetween: 30,
      speed: 1000,
      loop: true,
      autoplay: {
        delay: 10000,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".swiper-pagination",
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  }

  setImgShow() {
    if (!this.displayImg) return;
    let elementImgShow = document.getElementById('imgShow');
    let elementDiv = document.getElementById('container');
    let elementDivMap = document.getElementById('map');
    
    setTimeout(() => {
      let widthImg = elementImgShow.clientWidth;
      let heightImg = elementImgShow.clientHeight;
      let setWidth = elementDiv.clientWidth;
      let setHight = elementDiv.clientHeight;

      if (setHight != 861) {
        widthImg = (setHight * widthImg) / heightImg;
        heightImg = setHight;
      }
      if (setWidth > widthImg) {
        heightImg = (setWidth * heightImg) / widthImg;
        widthImg = setWidth;
      }

      elementDivMap.style.height = heightImg + 'px';
      elementDivMap.style.width = widthImg + 'px';
      elementImgShow.style.height = heightImg + 'px';

      this.panzoom = Panzoom(elementDivMap, {
        animate: false,
        duration: 700,
        contain: 'outside',
        maxScale: 1.9,
        minScale: 1,
      });

      // enable mouse wheel
    const parent = elementDiv.parentElement;
    elementDiv.addEventListener('dblclick', this.panzoom.reset);
    parent.addEventListener('wheel', this.panzoom.zoomWithWheel);

    elementDiv.addEventListener('panzoompan', (event: any) => {
      this.moving = true;
      if (!this.startPan) {
        this.startPan = true;
        this.moving = false;
      }
    });

    elementDiv.addEventListener('panzoomend', (event: any) => {
      if (this.moving) {
        if (this.funcOn) clearTimeout(this.funcOn);
        this.funcOn = setTimeout(() => {
          this.moving = false;
        });
      }
    });

    }, 500);
  }

  onShowImg(img: string) {
    this.displayImg = true;
    this.imgShow = img;
    setTimeout(() => {
      this.setImgShow();
    }, 300);
  }

  playVDO() {
    if (!this.product.Video) return;
    this.showVideo = true;
    this.Vdo = document.getElementById('vdo');
    this.Vdo.play();

    let iframe: any = document.getElementById('iframe'+this.productId);
    iframe.src = this.product.Video + '?autoplay=true';
  }

  closeShow() {
    this.displayImg = false;
  }

  onClose() {
    this.openPopup = false;
    this.status.emit(false);
  }

}
