import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/service/alert.service';
import { BoothService } from 'src/app/service/booth.service';
import { AlertInterface } from 'src/app/shareds/interface/alert.interface';
import { NavServiceService } from '../../service/nav-service.service';

@Component({
  selector: 'app-page-win',
  templateUrl: './page-win.component.html',
  styleUrls: ['./page-win.component.scss']
})
export class PageWinComponent implements OnInit {

  path = `assets/images/winner/`;
  data = [
    {
      title: "กติกาตอบคำถามชิงรางวัล",
      text: `1. เข้าตอบคำถามฯ ได้ตั้งแต่ วันที่ 10 – 16 ส.ค. 64 รวม 7 วัน
      2. การลงทะเบียนตอบคำถาม ทำครั้งเดียวที่บูธประชาสัมพันธ์ก่อนเข้าชมนิทรรศการ โดยระบุ ชื่อ-นามสกุล และเบอร์โทรศัพท์
      3. ในแต่ละวัน ผู้ลงทะเบียนแต่ละคนสามารถตอบคำถามได้ 1 ครั้ง โดยเมื่อส่งคำตอบแล้ว จะสามารถตอบคำถามในวันต่อไปได้ เมื่อมีการเปลี่ยนชุดคำถามประจำวันแล้ว โดยจะปิดการตอบคำถามวันสุดท้ายในเวลา 17.00 ของวันที่ ๑๖ ส.ค.๖๔
      4. ในแต่ละวัน มีคำถามจำนวน ๔ ข้อ ไม่ซ้ำกัน คำตอบแต่ละข้อเป็นแบบปรนัย มี 2 ตัวเลือก รวมจำนวนคำถามใน 7 วัน มีทั้งหมด 28 ข้อ
      5. รางวัล 
        5.1 รางวัลพิเศษ มี 2 รางวัล
        1) วันที่ 10 ส.ค. 64 มี 1 รางวัล
              - คัดเลือกผู้ได้รับรางวัล จากผู้ได้คะแนนสูงสุด 
          - ถ้ามีผู้ได้คะแนนสูงสุดเท่ากัน จะคัดเลือกผู้ได้รับรางวัล โดยให้คอมพิวเตอร์สุ่มจากหมายเลขโทรศัพท์ของผู้ที่มีคะแนนเท่ากัน     
          - ผู้ได้รับรางวัลพิเศษของวันที่ 10 ส.ค.64 แล้ว จะไม่มีสิทธิได้รับรางวัลอื่นอีก
        2) วันที่ 16 ส.ค. 64 มี 1 รางวัล
              - คัดเลือกผู้ได้รับรางวัล จากผู้ได้คะแนนรวมสูงสุดจากการตอบคำถามทั้ง 7 วัน
          - ถ้ามีผู้ได้คะแนนสูงสุดเท่ากัน จะคัดเลือกผู้ได้รับรางวัล โดยให้คอมพิวเตอร์สุ่มจากหมายเลขโทรศัพท์ของผู้ที่มีคะแนนเท่ากัน 
          - ผู้ได้รับรางวัลพิเศษของวันที่ 16 ส.ค.64 แล้ว จะไม่มีสิทธิได้รับรางวัลอื่นอีก
            5.2 รางวัลประจำวัน มีวันละ ๑๐ รางวัล รวมจำนวนทั้งหมด ๗๐ รางวัล
          - คัดเลือกผู้ได้รับรางวัล จากผู้มีคะแนนสูงสุด 10 อันดับแรกในแต่ละวัน ที่ไม่ซ้ำกับผู้ได้รับรางวัลพิเศษในข้อ 5.1 แล้ว 	
          - ในกรณีเมื่อเรียงลำดับคะแนนแล้ว มีผู้ตอบคำถามได้คะแนนเท่ากัน เกินกว่าจำนวนของรางวัลที่เหลือ จะคัดเลือกผู้ได้รับรางวัล โดยให้คอมพิวเตอร์สุ่มจากหมายเลขโทรศัพท์ของผู้ที่มีคะแนนเท่ากัน
      6. ของรางวัล
        6.1 รางวัลพิเศษ  2 รางวัล
          1) วันที่ 10 ส.ค.64  มี 1 รางวัล    หูฟังเกมมิ่ง (สีดำ) JBL รุ่น Quantum 200
          (๒) วันที่ 16 ส.ค.64  มี 1 รางวัล    ลำโพงเชื่อมต่อไร้สาย (สีดำ) JBL รุ่น Clip 3
            6.2 รางวัลประจำวัน มีวันละ 10 รางวัล รวมจำนวนทั้งหมด 70 รางวัล
          -หูฟังบลูทูธไร้สาย พร้อมกล่องชาร์จ  Asaki รุ่น A-K6654	
      7. การประกาศผล 
          จะประกาศผลผู้ได้รับรางวัลแต่ละวัน ระหว่างการ Live สดโดยพิธีกร ในวันรุ่งขึ้นระหว่างเวลา 09.00 น. – 09.30 น. ของวันที่ 11 ส.ค.64 - 16 ส.ค.64 และจะประกาศผลผู้ได้รับรางวัลทั้งหมดบนหน้าเว็บไซต์ ในวันที่  17 ส.ค. 64 เวลา 09.00 ทั้งนี้ การตัดสินผู้ได้รับรางวัลของคณะกรรมการฯ ถือเป็นข้อยุติ
      8. การมอบรางวัล จะมีเจ้าหน้าที่ใช้โทรศัพท์หมายเลข 082-234-3303 ติดต่อไปที่หมายเลชโทรศัพท์ที่ลงทะเบียนไว้ ในวันและเวลาราชการ เพื่อขอที่อยู่ในการจัดส่ง ทั้งนี้ผู้รับรางวัลไม่ต้องเสียค่าใช้จ่ายใดๆ ในการจัดส่งทั้งสิ้น 
      9. ทางนิทรรศการจะยกเลิกการมอบรางวัลแก่ผู้ตอบคำถามที่ลงทะเบียนด้วยหมายเลขโทรศัพท์ดังกล่าว ในกรณีดังต่อไปนี้ 
        9.1 ไม่สามารถติดต่อหมายเลขโทรศัพท์ที่ลงทะเบียนไว้ได้ แม้เจ้าหน้าที่จะได้พยายามติดต่อซ้ำแล้วเป็นเวลา ๒ วันขึ้นไป และไม่มีการติดต่อกลับจากหมายเลขโทรศัพท์ดังกล่าวภายในวันที่ 25 ส.ค. 64  
        9.2 ผู้รับโทรศัพท์ปฏิเสธว่าไม่ใช่ผู้ตอบคำถาม หรือปฏิเสธว่าไม่ได้ใช้หมายเลขโทรศัพท์ดังกล่าวลงทะเบียนในนิทรรศการ
        9.3 ผู้ตอบคำถามที่รับโทรศัพท์ ปฏิเสธการให้ที่อยู่สำหรับส่งรางวัลทางพัสดุไปรษณีย์ 
        9.4 ผู้ตอบคำถามที่รับโทรศัพท์ แจ้งขอสละสิทธิการรับรางวัล 
        9.5 พัสดุของรางวัลที่ส่งทางไปรษณีย์ถูกตีกลับ เนื่องจากไม่มีผู้รับ หรือไม่มีที่อยู่ตามที่แจ้งไว้
      10. ในกรณีของรางวัลที่ส่งทางไปรษณีย์ไม่ถึงผู้รับ อันเนื่องมาจากผู้ให้บริการไปรษณีย์เกิดเหตุขัดข้องในการนำส่งหรือพัสดุสูญหายระหว่างทาง คณะกรรมการจัดนิทรรศการฯ ขอสงวนสิทธิในการมอบของอย่างอื่นให้ทดแทนตามความเหมาะสม หรือไม่มอบของทดแทนก็ได้
      11. ผู้ได้รับรางวัล สามารถใช้หมายเลขโทรศัพท์ที่ลงทะเบียนไว้ สอบถามผลการส่งของรางวัลได้ที่หมายเลขโทรศัพท์ 082-234-3303 ในวัน และเวลาราชการ
      `,
    },
    {
      title: "ของรางวัล",
      text: `รางวัลใหญ่`,
      img1: `${this.path}w1.png`,
      img2: `${this.path}w2.png`,
      img3: `${this.path}w3.png`,
      img4: `${this.path}w4.jpg`,
      img5: `${this.path}w5.jpg`,
      text2: ``,

    },
    {
      title: "ประกาศรายชื่อผู้ได้รับรางวัล",
      text: `data test`,
    },
  ];

  // tab 1
  text1 = `
  1. เข้าตอบคำถามฯ ได้ตั้งแต่ วันที่ 10 – 16 ส.ค. 64 รวม 7 วัน ตอบคำถามได้ตลอด 24 ชั่วโมง

  2. การลงทะเบียนตอบคำถาม ทำครั้งเดียวที่บูธลงทะเบียนเข้างาน บริเวณซุ้มประตูทางเข้า โดยระบุชื่อ-นามสกุล และเบอร์โทรศัพท์

  3. ในแต่ละวัน ผู้ลงทะเบียนแต่ละคนสามารถตอบคำถามได้1 ครั้ง หลังจากส่งคำตอบแล้ว จะสามารถตอบ
  คำถามได้อีกครั้งในวันต่อไป ชึ่งชุดคำถามจะถูกเปลี่ยนใหม่โดยอัตโนมัติทุกๆวันหลังเวลา 24.00 น และจะปิด
  การตอบคำถามวันสุดท้าย คือ วันที่ 16 ส.ค.64 เวลา 24.00 น. 

  4. ในแต่ละวัน มีคำถามจำนวน 4 ข้อ ไม่ซ้ำกัน คำตอบแต่ละข้อเป็นแบบปรนัย มี2 ตัวเลือก รวมจำนวน
  คำถามใน 7 วัน มีทั้งหมด 28 ข้อ

  5. รางวัล
         5.1 รางวัลพิเศษ มี 4 รางวัล สุ่มแจกสำหรับผู้เข้าร่วมกิจกรรมตอบคำถามที่ได้คะแนนเต็ม
         5.2 รางวัลอื่นๆ มี 80 รางวัล สำหรับผู้เข้าร่วมกิจกรรมตอบคำถาม 

  6. ของรางวัล
         6.1 รางวัลพิเศษ 4 รางวัล
              1) วันที่ 10 ส.ค.64 มี1 รางวัล หูฟังเกมมิ่ง (สีดำ) JBL รุ่น Quantum 200
              2) วันที่ 16 ส.ค.64 มี1 รางวัล ลำโพงเชื่อมต่อไร้สาย (สีดำ) JBL รุ่น Clip 3
              3) หูฟังบลูทูธไร้สาย มี1 รางวัล พร้อมกล่องชาร์จ Asaki รุ่น A-K6654
              4) เม้าส์ไร้สาย มี1 รางวัล 
         6.2 รางวัลอื่นๆ 80 รางวัล

  7. การประกาศผล
          การประกาศผลผู้ได้รับรางวัลทั้งหมดบนหน้าเว็บไซต์ ในวันที่ 17 ส.ค. 64 เวลา 12.00 ทั้งนี้ การ
  ตัดสินผู้ได้รับรางวัลของคณะกรรมการฯ ถือเป็นข้อยุติ

  8. การมอบรางวัล จะมีเจ้าหน้าที่ใช้โทรศัพท์หมายเลข 082-234-3303 ติดต่อไปที่หมายเลขโทรศัพท์ที่
  ลงทะเบียนไว้ ในวันและเวลาราชการ เพื่อขอที่อยู่ในการจัดส่ง ทั้งนี้ผู้รับรางวัลไม่ต้องเสียค่าใช้จ่ายใดๆ ในการ
  จัดส่งทั้งสิ้น

  9. ทางนิทรรศการจะยกเลิกการมอบรางวัลแก่ผู้ตอบคำถามที่ลงทะเบียนด้วยหมายเลขโทรศัพท์ดังกล่าว ใน
  กรณีดังต่อไปนี้ 

              9.1 ไม่สามารถติดต่อหมายเลขโทรศัพท์ที่ลงทะเบียนไว้ได้ แม้เจ้าหน้าที่จะได้พยายามติดต่อซ้ำแล้ว
                  เป็นเวลา 2 วันขึ้นไป และไม่มีการติดต่อกลับจากหมายเลขโทรศัพท์ดังกล่าวภายในวันที่ 25 ส.ค. 64

              9.2 ผู้รับโทรศัพท์ปฏิเสธว่าไม่ใช่ผู้ตอบคำถาม หรือปฏิเสธว่าไม่ได้ใช้หมายเลขโทรศัพท์ดังกล่าว
                  ลงทะเบียนในนิทรรศการ

              9.3 ผู้ตอบคำถามที่รับโทรศัพท์ปฏิเสธการให้ที่อยู่สำหรับส่งรางวัลทางพัสดุไปรษณีย์

              9.4 ผู้ตอบคำถามที่รับโทรศัพท์ แจ้งขอสละสิทธิ์การรับรางวัล

              9.5 พัสดุของรางวัลที่ส่งทางไปรษณีย์ถูกตีกลับ เนื่องจากไม่มีผู้รับ หรือไม่มีที่อยู่ตามที่แจ้งไว้

  10. ในกรณีของรางวัลที่ส่งทางไปรษณีย์ไม่ถึงผู้รับ อันเนื่องมาจากผู้ให้บริการไปรษณีย์เกิดเหตุขัดข้องในการ
  นำส่งหรือพัสดุสูญหายระหว่างทาง คณะกรรมการจัดนิทรรศการฯ ขอสงวนสิทธิในการมอบของอย่างอื่นให้
  ทดแทนตามความเหมาะสม หรือไม่มอบของทดแทนก็ได้

  11. ผู้ได้รับรางวัล สามารถใช้หมายเลขโทรศัพท์ที่ลงทะเบียนไว้ สอบถามผลการส่งของรางวัลได้ที่หมายเลข
  โทรศัพท์ 082-234-3303 ในวัน และเวลาราชการ`;

  //tab 2
  text2 = `รางวัลใหญ่`;
  text3 = ``;
  img1 = `${this.path}w1.png`;
  img2 = `${this.path}w2.png`;
  img3 = `${this.path}w3.png`;
  img4 = `${this.path}w4.jpg`;
  img5 = `${this.path}w5.jpg`;

  // tab 3
  winnerGet: winnerData[];

  constructor(
    private navService: NavServiceService,
    private boothService: BoothService,
    public alertSer: AlertService,
  ) { }

  ngOnInit(): void {
    this.navService.showNavbar();
    this.getWinner();
  }

  getWinner() {
    this.boothService.getReward().subscribe(
      (res: winnerData[]) => {
        this.winnerGet = res;
      },
      err => {
        let dataAlert: AlertInterface;
        dataAlert = { severity: 'error', title: 'error', detail: err.message }
        this.alertSer.setAlert(dataAlert);
      }
    );
  }

}


interface winnerData {
  Limit: number;
  ReceivedCustomers: ReceivedCustomersData[];
  RewardId: number;
  RewardName: string;
}

interface ReceivedCustomersData {
  CustomerId: number;
  CustomerName: string;
  // CustomerPhoneNo: string;
}
