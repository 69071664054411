import { Component, OnInit } from '@angular/core';
import { NavServiceService } from '../../service/nav-service.service';

@Component({
  selector: 'app-recommended',
  templateUrl: './recommended.component.html',
  styleUrls: ['./recommended.component.scss']
})
export class RecommendedComponent implements OnInit {

  constructor(
    private navService: NavServiceService,
  ) {
    this.navService.showNavbar();
  }

  ngOnInit(): void {

  }

}
