import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AlertInterface } from '../shareds/interface/alert.interface';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  alert = new BehaviorSubject<AlertInterface>(null);

  constructor() { }

  getAlert() {
    return this.alert;
  }

  setAlert(data: AlertInterface) {
    this.alert.next(data);
  }

}

