import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavServiceService {

  displayNavbar = false;

  constructor() { }

  closeNavbar() {
    this.displayNavbar = false;
  }

  showNavbar() {
    this.displayNavbar = true;
  }
}
