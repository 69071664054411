<!-- <div class="container">
    <p class="head-title">กำหนดการ</p>
    <div class="d-flex">
        <div class="time-size">
            <p class="head-text">เวลา</p>
        </div>
        <div class="text-size">
            <p class="head-text">กิจกรรม</p>
        </div>
    </div>
    <div class="d-flex" *ngFor="let timeData of data">
        <div class="time-size">
            <p>{{ timeData.time }}</p>
        </div>
        <div class="text-size">
            <p *ngFor="let dataText of timeData.text">{{ dataText }}</p>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-12" style="color: red;">หมายเหตุ: กำหนดการอาจจะมีการเปลี่ยนแปลงตามความเหมาะสม

        </div>
    </div>
</div> -->
<br><br><br>
<br><br><br>
<div class="container">
    <div class="row justify-content-center">
        <div class="col-6 text-right">
            <img src="../../../../../assets/images/icon/ncit.png" alt="" width="40%">
        </div>
        <div class="col-6 text-left">
            <img src="../../../../../assets/images/icon/pt.png" alt="" width="37%">
        </div>
    </div>
    <br>
    <!-- <p class="test-text">
            {{text}}
        </p> -->
    <!-- 1 -->
    <div class="row justify-content-center">
        <div class="col-12  test-text" style="font-weight: 700;">
            กำหนดการจัดกิจกรรมให้ความรู้ส่งเสริม สนับสนุนการประยุกต์ใช้เทคโนโลยี AI และ Big Data แบบออนไลน์
        </div>
    </div>
    <br>
    <div class="row justify-content-center text-center mb-4 mt-5">
        <div class="col-12  test-text text-center">
            วันที่อังคารที่ 10 สิงหาคม พ.ศ. 2564
        </div>
    </div>
    <br>
    <div class="row justify-content-center">
        <div class="col-2 test-text text-left">09.00 น. </div>
        <div class="col-10 test-text">เริ่มเปิดให้เข้าชมงานนิทรรศการ AI และ Big Data กองทัพเรือ ประจำปี 2564
            แบบออนไลน์
        </div>
    </div>
    <div class="row justify-content-center ">
        <div class="col-2 test-text">09.15 น. </div>
        <div class="col-10 test-text">จก.สสท.ทร. กล่าววัตถุประสงค์ของการจัดงานนิทรรศการฯ</div>
    </div>
    <div class="row justify-content-center">
        <div class="col-2 test-text">09.30 น.</div>
        <div class="col-10 test-text">ผบ.ทร. ประธานในพิธีเปิดงานฯ กล่าวเปิดงานนิทรรศการฯ</div>
    </div>
    <div class="row justify-content-center">
        <div class="col-2 test-text">09.30 น. - 10.30 น.</div>
        <!-- <div class="col-10 test-text">การบรรยายแบบออนไลน์ เรื่อง “เทคโนโลยี AI และ Big Data กับการประยุกต์ใช้ในกองทัพเรือ” </div> -->
        <div class="col-10 test-text">การบรรยายแบบออนไลน์โดย ศาสตราจารย์ ดร.ธนารักษ์ ธีระมั่นคง <br>เรื่อง
            “เทคโนโลยี
            AI และ Big Data กับการประยุกต์ใช้ในกองทัพเรือ”</div>
    </div>
    <div class="row justify-content-center">
        <div class="col-2 test-text">10.30 น. เป็นต้นไป </div>
        <div class="col-10 test-text">เยี่ยมชมนิทรรศการฯ ผ่านระบบสารเทศออนไลน์เสมือนจริง (Virtual Exhibition)
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-2 test-text">13.00 น. – 16.30 น.</div>
        <div class="col-10 test-text">การจัดรายการให้ความรู้ โดยพิธีกรออนไลน์
            ผ่านคลิปวิดีโอให้ความรู้ขององค์กรร่วมจัด</div>
    </div>
    <!-- 1 -->
    <br>
    <br>
    <div class="row justify-content-center text-center mb-4">
        <div class="col-12  test-text text-center">
            วันที่ 11 - 16 สิงหาคม พ.ศ. 2564
        </div>
    </div>
    <br>
    <!-- 2 -->
    <div class="row justify-content-center">
        <div class="col-2 test-text">08.00 น. เป็นต้นไป </div>
        <div class="col-10 test-text">
            เปิดให้เข้าชมงานนิทรรศการ AI และ Big Data กองทัพเรือ ประจำปี 2564 แบบออนไลน์
            <br>และเยี่ยมชมนิทรรศการฯ ผ่านระบบสารเทศออนไลน์เสมือนจริง (Virtual Exhibition)
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-2 test-text">09.00 น. - 09.30 น.</div>
        <div class="col-10 test-text">พิธีกร LIVE สด
            <br>&nbsp;&nbsp;&nbsp;&nbsp; - แนะนำบูธขององค์กรที่มาร่วมจัดนิทรรศการในส่วนที่เป็น highlight
            ของแต่ละบูธ
            (วันละ 3 – 4 บูธ)
            <br>&nbsp;&nbsp;&nbsp;&nbsp; - แนะนำปัญหาชิงรางวัลประจำวัน
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-2 test-text">09.30 น. – 10.00 น.</div>
        <div class="col-10 test-text">
            วิทยากรบรรยายแบบออนไลน์ให้ความรู้เรื่อง AI (เฉพาะ 11 – 12 สิงหาคม 2564)
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-2 test-text">(11 สิงหาคม 2564)</div>
        <div class="col-10 test-text">
            &nbsp;&nbsp;&nbsp;&nbsp;ดร.กอบกฤตย์ วิริยะยุทธกร เรื่อง “เทคโนโลยีปัญญาประดิษฐ์”
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-2 test-text">(12 สิงหาคม 2564)</div>
        <div class="col-10 test-text">
            &nbsp;&nbsp;&nbsp;&nbsp;น.ท.มนต์ชัย บุญยกิตานนท์ เรื่อง “การนำ AI มาใช้ในปฏิบัติการทางทหาร”
        </div>
    </div>
    <br>
    <!-- 2 -->
    <br>
    <br>
    <div class="row justify-content-center text-center mb-4">
        <div class="col-12  test-text text-center">
            วันที่ 17 สิงหาคม พ.ศ. 2564
        </div>
    </div>
    <br>
    <!-- 3 -->
    <div class="row justify-content-center mb-5">
        <div class="col-2 test-text">12.00 น.</div>
        <div class="col-10 test-text">
            ประกาศผลผู้ได้รับรางวัลจากการตอบปัญหาทั้งหมดบนหน้าเว็บไซต์นิทรรศการ
        </div>
    </div>
    <!-- 3 -->
    <div class="row justify-content-center">
        <div class="col-12 test-text">
            หมายเหตุ เข้าชมงานนิทรรศการฯ ออนไลน์ผ่านเว็บไซต์
            <a href="https://นิทรรศการกองทัพเรือ.com">https://นิทรรศการกองทัพเรือ.com</a> หรือแสกน QR ที่แนบ
        </div>
    </div>
    <br>
    <div class="row justify-content-center">
        <div class="col-12 text-center">
            <img src="../../../../../assets/images/qrcode.jpg" alt="">
        </div>
    </div>
    <br><br>
</div>