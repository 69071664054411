<p-dialog header="product" [(visible)]="openPopup" [modal]="true" [style]="{width: '60vw'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false" (onHide)="onClose()" [styleClass]="'faq'" [showHeader]="false">
    <img class="icon-close" [src]="iconClose" alt="icon-close" (click)="onClose()">
    <div class="container-fluid" *ngIf="product">
        <p class="title">{{ product.ProductName }}</p>
        <p *ngIf="product && !product.Video && showVideo && !product.Images && !product.PDF" class="text-center" style="font-size: 18px;">ไม่มีข้อมูล</p>
        <div class="position-relative" style="padding: 15px">
            <ng-container *ngIf="!showVideo">
                <img *ngIf="product.Video" class="position-absolute" style="top:50%;left:45%;width:50px;cursor: pointer;" [src]="iconVideo" alt="" (click)="playVDO() ">
                <img [src]="product && product.VideoImage" width="100%" style="border-radius: 10px;" alt="">
            </ng-container>
            <video id="vdo" controls style="width: 100%;height: 250px;" [ngClass]="{'d-none': !showVideo || (product && !product.Video || !typeVDO) }">
                <source id="source" [src]="dataVod" type="video/mp4">
            </video>
            <div style="position: relative; padding-top: 56.25%;" [ngClass]="{'d-none': !showVideo || (product && !product.Video) || typeVDO}">
                <iframe [id]="'iframe'+productId" src="https://iframe.videodelivery.net/64618606d685cc7207c60501ba7a44fc?autoplay=true" style="border: none; position: absolute; top: 0; height: 100%; width: 100%;" allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                    allowfullscreen="true">
                </iframe>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="swiper-container mySwiper">
                    <div class="swiper-wrapper">
                        <div *ngFor="let img of product.Images; let index = index" class="swiper-slide">
                            <img [src]="img.Image" alt="" style="max-height: 100px; cursor: pointer;" (click)="onShowImg(img.Image)">
                        </div>
                    </div>
                    <div class="swiper-button-next"></div>
                    <div class="swiper-button-prev"></div>
                    <div class="swiper-pagination"></div>
                </div>
            </div>
        </div>
        <pre *ngIf="product && product.ProductDetail">{{ product.ProductDetail }}</pre>
        <div class="row">
            <div class="col-md-6 col-12 ml-auto" [ngClass]="{'d-none': product && !product.PDF}">
                <div class="text-center btn-operator p-3">
                    <a class="btn btn-custom" [href]="product.PDF" download>
                        <img [src]="iconChat" alt=""> ดาวน์โหลดโบรชัวร์
                    </a>
                </div>
            </div>
            <!-- <div class="col-md-6 col-12">
                <div class="text-center btn-operator p-3 btn-last">
                    <button class="btn btn-custom" (click)="openChat()">
                        <img [src]="iconChat" alt="">
                        แชทสอบถามข้อมูล
                    </button>
                </div>
            </div> -->
        </div>
    </div>
</p-dialog>


<p-dialog header="test" [(visible)]="displayImg" [modal]="true" [style]="{width: '60vw'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false" (onHide)="closeShow()" [styleClass]="'faq show-img'" [showHeader]="false">
    <img class="icon-close" [src]="iconClose" alt="icon-close" (click)="closeShow()">
    <!-- <button class="btn btn-primary position-absolute zoom-in" (click)="panzoom.zoomIn()">
        zoomin
    </button>
    <button class="btn  btn-primary position-absolute zoom-out" (click)="panzoom.zoomOut()">
        zoomout
    </button>
    <button class="btn  btn-primary position-absolute zoom-reset" (click)="panzoom.reset()">
        reset
    </button> -->
    <div id="container" class="container">
        <div id="map" class="map">
            <img id="imgShow" [src]="imgShow" alt="">
        </div>
    </div>
</p-dialog>