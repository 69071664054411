import { BoothService } from './../../../../service/booth.service';
import { NavServiceService } from './../../service/nav-service.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { scenes } from '../../typescript/scense';
import { AppService } from 'src/app/app.service';
import { AlertInterface } from 'src/app/shareds/interface/alert.interface';
import { AlertService } from 'src/app/service/alert.service';

declare let pannellum;

@Component({
  selector: 'app-panorama',
  templateUrl: './panorama.component.html',
  styleUrls: ['./panorama.component.scss']
})
export class PanoramaComponent implements OnInit {

  controlPannellum: any;
  startOpen = false;
  vdo = true;
  displayVdo = true;
  canvasEl: any;
  context: any;
  elementVDO: any;
  fixNone = false;
  position = 14;

  Popup: number;

  constructor(
    private router: Router,
    private atvRouter: ActivatedRoute,
    private navService: NavServiceService,
    private boothService: BoothService,
    public alertSer: AlertService,
    private app: AppService
  ) {
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.getDataPath();
    }, 1500);
    this.boothService.getBooth().subscribe(
      res => {
        // console.log("res data : ", res);
      }, err => {
        let dataAlert: AlertInterface;
        dataAlert = { severity: 'error', title: 'โหวตไม่สำเร็จ', detail: err.message }
        this.alertSer.setAlert(dataAlert);
      }
    )
  }

  getDataPath() {
    let status = this.atvRouter.snapshot.paramMap.get("status");
    this.startOpen = (status === "start");
    this.fixNone = (status === "1");
    if (status !== "start") {
      this.position = parseInt(status);
    } else {
      this.position = 14;
    }
    this.elementVDO = document.getElementById("video");
    this.elementVDO.style.display = 'none';
    this.canvasEl = document.getElementById('canvas');
    this.context = this.canvasEl.getContext('2d');
    this.canvasEl.width = window.innerWidth;
    this.canvasEl.height = window.innerHeight;

    if (this.startOpen) {
      this.elementVDO.play();
      requestAnimationFrame(() => this.updateCanvas());
      setTimeout(() => {
        this.vdo = false;
        setTimeout(() => {
          this.displayVdo = false;
          this.router.navigateByUrl('home/14');
        }, 500);
      }, 7000);
    } else {
      this.canvasEl.style.display = 'none';
    }

    this.navService.showNavbar();
    this.setPannellum();
  }

  setPannellum() {
    this.controlPannellum = pannellum.viewer('panorama', {
      "default": {
        "firstScene": `position${this.position}`,
        "sceneFadeDuration": 1000,
        "autoLoad": true,
        // "autoRotate": -2,
        // "autoRotateInactivityDelay": 3000,
        "showZoomCtrl": false,  // zoom +, -
        "keyboardZoom": false,
      },
      "scenes": scenes(this)
    });

    this.controlPannellum.on("scenechange", () => {
      let scence: string = this.controlPannellum.getScene();
      this.position = parseInt(scence.substr(8));
      this.router.navigateByUrl(`home/${this.position}`);
    });
  }

  viewIcon(hotSpotDiv: any, args: string) {
    hotSpotDiv.classList.add(`${args}`);
  }

  hotspotCustom(hotSpotDiv, args) {
    if (window.innerWidth <= 1028) {
      if (args.length > 10) {
        args = args.substring(0, 10) + "..";
      }
    }
    hotSpotDiv.classList.add('hotspot-title');
    var icon = document.createElement('img');
    icon.src = '/assets/images/icon/eye.png';
    if ('ออกจากงาน' === args) {
      icon.src = '/assets/images/icon/exit.png';
    }
    icon.classList.add("icon-show");
    var span = document.createElement('span');
    span.innerHTML = args;
    hotSpotDiv.appendChild(icon);
    hotSpotDiv.appendChild(span);
  }

  updateCanvas() {
    this.context.drawImage(this.elementVDO, 0, 0, this.canvasEl.width, this.canvasEl.height);
    window.requestAnimationFrame(() => this.updateCanvas());
  }

  openPopup(num: number) {
    this.Popup = num;
  }

  closePopup() {
    this.Popup = 0;
  }

  NextPage(id: number) {
    if (id == -14) { this.app.logout(); return; }
    // if (id < 0) {
    //   this.openPopup(id);
    //   return;
    // }
    this.router.navigateByUrl(`home-${this.position}/booth/${id}`);
  }

}
