import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  loading: boolean;
  countLoading: number;

  constructor() {
    this.loading = false;
    this.countLoading = 0;
  }

  changeCount(change: number) {
    setTimeout(() => {
      this.countLoading += change;
      this.loading = (this.countLoading > 0);
    }, );
  }

  resetCount() {
    setTimeout(() => {
      this.countLoading = 0;
      this.loading = false;
    }, );
  }
}
