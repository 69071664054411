import { HttpService } from './../shareds/services/http.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BoothService {

  constructor(
    private http: HttpService
  ) {
    
  }

  getBooth() {
    return this.http.methodGet(`ms/booth/find`);
  }

  findBooth(boothId: number) {
    return this.http.methodGet(`ms/booth/get/${boothId}`);
  }

  getProduct(boothId: number) {
    return this.http.methodGet(`ms/product/find/${boothId}`);
  }

  findProduct(productId: number) {
    return this.http.methodGet(`ms/product/get/${productId}`);
  }

  getFAQ(boothId: number) {
    return this.http.methodGet(`ms/faq/find/${boothId}`);
  }

  putVote(boothId: number) {
    return this.http.methodPut(`ms/booth/vote/${boothId}`,{boothId: boothId});
  }

  getReward() {
    return this.http.methodGet(`ms/reward/customerreceived`);
  }

}
