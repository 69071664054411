<p-dialog header="วิดีโอ" [(visible)]="openPopup" [modal]="true" [style]="{width: '50vw'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false" (onHide)="onClose()" [styleClass]="'video'">
    <div class="accordion" id="accordionExample">
        <div class="card" style="border: unset">
            <p *ngIf="!linkVideo || linkVideo === ''" class="text-center" style="font-size: 18px;">ไม่มีข้อมูล</p>
            <video id="video" controls [ngClass]="{'d-none': !linkVideo || linkVideo === '' || !typeVDO}">
                <source src="assets/file/welcome.mp4" type="video/mp4">
            </video>
            <div style="position: relative; padding-top: 56.25%;" [ngClass]="{'d-none': !linkVideo || linkVideo === '' || typeVDO}">
                <iframe id="iframe" src="https://iframe.videodelivery.net/64618606d685cc7207c60501ba7a44fc?autoplay=true" style="border: none; position: absolute; top: 0; height: 100%; width: 100%;" allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                    allowfullscreen="true">
                </iframe>
            </div>
        </div>
    </div>
</p-dialog>