import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { appUrl } from './appUrl';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  Authorization: string;
  urlSignalr = "https://api.thainavy.org/signalr";
  constructor(private router: Router) { }

  logout() {
    this.Authorization = "";
    localStorage.removeItem('mstk');
    this.router.navigate(['/', appUrl.start])
  }
}
