import { PrimeNGConfig } from 'primeng/api';
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { DomSanitizer } from '@angular/platform-browser';
declare let Swiper;

@Component({
  selector: 'app-stage',
  templateUrl: './stage.component.html',
  styleUrls: ['./stage.component.scss']
})
export class StageComponent implements OnInit {

  @Output() status = new EventEmitter<boolean>();

  openPopup = true;
  iconClose = 'assets/images/icon/Close.png';

  path = 'assets/images/pb/';
  showVideo = `${this.path}/mv1.mp4`;
  imgSlide = [
    `${this.path}im1.jpg`,
    `${this.path}im2.jpg`,
    `${this.path}im3.jpg`,
    `${this.path}im4.jpg`,
    // `${this.path}p5.jpg`,
    // `${this.path}p6.jpg`,
    // `${this.path}p7.jpg`,
    // `${this.path}p8.jpg`,
    // `${this.path}p9.jpg`,
    // `${this.path}p10.jpg`
  ];

  constructor(private primengConfig: PrimeNGConfig,
    private http: HttpService,
    private sanitizer: DomSanitizer) {
    setTimeout(() => {
      let swiper = new Swiper(".mySwiper", {
        slidesPerView: 1,
        spaceBetween: 30,
        speed: 1000,
        loop: true,
        autoplay: {
          delay: 1000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    }, 500);

  }

  itemsVdo: any = [];
  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.http.methodGet(`ms/settingdata/mainstate`).subscribe(res => {
      this.itemsVdo = res;
      this.itemsVdo.forEach(f => {
        f.SettingDataValue = this.sanitizer.bypassSecurityTrustHtml(f.SettingDataValue)
      });
    })
  }

  onOpen() {
    this.openPopup = true;
  }

  onClose() {
    this.openPopup = false;
    this.status.emit(false);
  }

  setSwiper() {
    let swiper = new Swiper(".mySwiper", {
      pagination: {
        el: ".swiper-pagination",
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  }

}
