<!-- <div class="container" style="padding-top: 92px;">
    <p class="head-title text-center">กติกาตอบคำถามชิงรางวัล</p>
    <div class="card">
        <p-tabView>
            <ng-container *ngFor="let law of data; let index = index">
                <p-tabPanel [header]="law.title" headerStyleClass="law-head">
                    <p>{{ law.text }}</p>
                    <img *ngIf="law.img1" [src]="law.img1" alt="">
                    <img *ngIf="law.img2" [src]="law.img2" alt="">
                    <p *ngIf="law.text2">{{ law.text2 }}</p>
                    <img *ngIf="law.img3" [src]="law.img3" alt="">
                </p-tabPanel>
            </ng-container>
        </p-tabView>
    </div>
</div> -->

<div class="container" style="padding-top: 92px;">
    <p class="head-title text-center">กิจกรรม</p>
    <img src="../../../../../assets/images/rw.jpg" alt="" width="100%">
    <br>
    <br>
    <br>
    <br>
    <div class="card">
        <p-tabView>
            <p-tabPanel header="กติกาตอบคำถามชิงรางวัล" headerStyleClass="law-head">
                <p>{{ text1 }}</p>
            </p-tabPanel>
            <p-tabPanel header="ของรางวัล" headerStyleClass="law-head">
                <!-- <h1 style="font-weight: 600;color: #333;font-family: 'Sarabun', sans-serif !important;" class="my-5 text-center">{{
                    text2 }}</h1> -->
                <!-- <div class="text-center">
                    <img style="max-width: -webkit-fill-available; width: 20%;" [src]="img5" alt="">
                </div> -->
                <div class="text-center">
                    <img style="max-width: -webkit-fill-available;" [src]="img1" alt="">
                    <img style="max-width: -webkit-fill-available;" [src]="img2" alt="">
                </div>

                <h1 style="font-weight: 600;margin-top: 50px !important;;color: #333;font-family: 'Sarabun', sans-serif !important;"
                    class="my-5 text-center">{{ text3 }}</h1>
                <div class="text-center">
                    <img style="max-width: -webkit-fill-available;" [src]="img3" alt="">
                    <img style="max-width: -webkit-fill-available;" [src]="img4" alt="">
                </div>
            </p-tabPanel>
            <p-tabPanel header="ประกาศรายชื่อผู้ได้รับรางวัล" headerStyleClass="law-head">
                <ng-container *ngFor="let winner of winnerGet; let index = index">
                    <ng-container *ngIf="winner.ShowHallOfFrame">
                        <p style="font-weight: 600;" class="mb-3 mt-4">{{ winner.RewardName }}</p>
                        <ng-container *ngFor="let userWiner of winner.ReceivedCustomers; let winIndex = index">
                            <p> - {{ (winIndex+1) +". "+ userWiner.CustomerName }}</p>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>
<br>
<br>
<br>