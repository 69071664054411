import { NavServiceService } from './../../service/nav-service.service';
import { PositionHome } from './../../interface/position.interface';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Panzoom from '@panzoom/panzoom';
import position from '../../json/position.json';
import positionZone from '../../json/positionZone.json';
import zoneA from '../../json/zoneA.json';
import pointMap from '../../json/pointMap.json';
import pointPinMap from '../../json/pointPinMap.json';
import { LoadingService } from 'src/app/service/loading.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  imgMap = 'assets/images/homePage/MAP-New.png';
  imgStart = 'assets/images/homePage/MAP-New.png';
  // imgEnd = [
  //   "assets/images/homePage/endZoneA.jpg",
  //   "assets/images/homePage/endZoneB.jpg",
  //   "assets/images/homePage/endZoneC.jpg",
  // ];
  imgEnd = 'assets/images/homePage/endZoneA.jpg'
  iconEye = 'assets/images/icon/eye.png';
  iconEyeWhite = 'assets/images/icon/eye-white.png';
  iconWalk = 'assets/images/icon/moveMap.png';
  iconMap = 'assets/images/icon/brochure.png';
  pathIcon = 'assets/images/homePage/'; 

  // positionData: PositionHome[];
  // zoneAData: PositionHome[];
  // posSelect: PositionHome;
  pointMapData: PositionHome[];
  pointPinMapData: PositionHome[];

  panzoom: any;
  elementContainer: any;
  elementDivMap: any;
  elementImgMap: any;
  elementVDO: any;
  sourceVDO: any;
  setWidth = 1531;
  setHeight = 861;

  canvasEl: any;
  context: any;

  funcOn: any;
  moving = false;
  startPan = false;
  onVideo = false;
  onZone = false;
  menuOn = false;
  
  constructor(
    private router: Router,
    private navService: NavServiceService,
    private loadingService: LoadingService,
  ) {
    // this.positionData = positionZone.items;
    // this.zoneAData = zoneA.items;
    this.pointMapData = pointMap.booth;
    this.pointPinMapData = pointPinMap.pin;
  }

  ngOnInit(): void {
    this.loadingService.changeCount(1);
    this.navService.showNavbar();
    setTimeout(() => {
      this.loadingService.resetCount();
      this.setMap();
    }, 500);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.panzoom.destroy();
    this.loadingService.changeCount(1);
    setTimeout(() => {
      this.loadingService.resetCount();
      this.setMap();
    }, 500);
  }

  setMap() {
    this.elementContainer = document.getElementById('container');
    this.elementDivMap = document.getElementById('map');
    this.elementImgMap = document.getElementById('imgMap');
    this.elementImgMap.src = this.imgMap;
     
    let innerHeight = window.innerHeight;
    let innerWidth = window.innerWidth;
    this.elementContainer.style.maxWidth = window.innerWidth + 'px';
    this.elementContainer.style.maxHeight = innerHeight + 'px';
    if (innerHeight != 861) {
      this.setWidth = (innerHeight * this.setWidth) / this.setHeight;
      this.setHeight = innerHeight;
    }
    if (innerWidth > this.setWidth) {
      this.setHeight = (innerWidth * this.setHeight) / this.setWidth;
      this.setWidth = innerWidth;
    }
    this.elementDivMap.style.height = this.setHeight + 'px';
    this.elementDivMap.style.width = this.setWidth + 'px';
    this.elementImgMap.style.height = this.setHeight + 'px';
    // console.log('setHeight', this.setHeight);
    // console.log('setWidth', this.setWidth);
    let heightS = this.setHeight / 100;
    let widthS = this.setWidth / 100;
    // for (let item of this.positionData) {
    //   item.left = widthS * item.leftPersen;
    //   item.top = heightS * item.topPersen;
    // }
    // for (let item of this.zoneAData) {
    //   item.left = widthS * item.leftPersen;
    //   item.top = heightS * item.topPersen;
    // }
    for (let item of this.pointMapData) {
      item.left = widthS * item.leftPersen;
      item.top = heightS * item.topPersen;
    }
    for (let item of this.pointPinMapData) {
      item.left = widthS * item.leftPersen;
      item.top = heightS * item.topPersen;
    }
    this.elementVDO = document.getElementById('video');
    this.elementVDO.style.display = 'none';
    this.sourceVDO = document.getElementById('source');
    this.canvasEl = document.getElementById('canvas');
    this.context = this.canvasEl.getContext('2d');
    this.canvasEl.width = window.innerWidth;
    this.canvasEl.height = window.innerHeight;
    
    requestAnimationFrame(() => this.updateCanvas());

    this.panzoom = Panzoom(this.elementDivMap, {
      animate: false,
      duration: 700,
      contain: 'outside',
      maxScale: 1.9,
      minScale: 1,
    });

    // enable mouse wheel
    const parent = this.elementDivMap.parentElement;
    this.elementDivMap.addEventListener('dblclick', this.panzoom.reset);
    parent.addEventListener('wheel', this.panzoom.zoomWithWheel);

    this.elementDivMap.addEventListener('panzoompan', (event: any) => {
      this.moving = true;
      if (!this.startPan) {
        this.startPan = true;
        this.moving = false;
      }
    });

    this.elementDivMap.addEventListener('panzoomend', (event: any) => {
      if (this.moving) {
        if (this.funcOn) clearTimeout(this.funcOn);
        this.funcOn = setTimeout(() => {
          this.moving = false;
        });
      }
    });
  }

  nextPage(pin: any) {
    // return;
    if (pin.panorama) {
      this.router.navigateByUrl(`home/${pin.panorama}`);
    } else {
      if (pin.id === -1) {
        this.router.navigateByUrl(`learning`);
        return;
      }
      this.router.navigateByUrl(`walk/booth/${pin.id}`);
    }
    // if(path === 'eye') {
    //   this.router.navigateByUrl(`walk/booth/${id}`);
    // } else {
    //   this.router.navigateByUrl(`home/${id}`);
    // }
  }

  // moveMap(dataPos: PositionHome) {
  //   if (!dataPos.end) return;
  //   this.posSelect = dataPos;
  //   this.sourceVDO.src = this.posSelect.start;
  //   this.elementVDO.load();
  //   this.elementVDO.play();
  //   this.onVideo = true;
  //   setTimeout(() => {
  //     setTimeout(() => {
  //       this.onZone = true;
  //       this.elementImgMap.src = this.posSelect.imgEnd;
  //       this.imgMap = this.posSelect.imgEnd;
  //     }, 100);
  //     this.elementVDO.onended= ()=>{
  //       this.onVideo = !this.onVideo;
  //       this.menuOn = true;
  //     };  
  //   }, );
  // }

  // End() {
  //   this.sourceVDO.src = this.posSelect.end;
  //   this.elementVDO.load();
  //   this.elementVDO.play();
  //   this.onVideo = true;
  //   this.menuOn = false;
  //   setTimeout(() => {
  //     setTimeout(() => {
  //       this.onZone = false;
  //       this.elementImgMap.src = this.imgStart;
  //       this.imgMap = this.imgStart; 
  //     }, 100);
  //     this.elementVDO.onended= ()=>{
  //       this.onVideo = !this.onVideo;
  //     }; 
  //   });
  // }

  updateCanvas() {
    this.context.drawImage(this.elementVDO, 0, 0, this.canvasEl.width, this.canvasEl.height);
    window.requestAnimationFrame(() => this.updateCanvas());
  }

}


// {
        //     "id": 1,
        //     "img": "p360.png",
        //     "class": "pin",
        //     "topPersen": 49.0913721568,
        //     "leftPersen": 27.9702534448,
        //     "top": 548,
        //     "left": 667,
        //     "name": "pin",
        //     "panorama": "12"
        // },