<br><br><br><br>
<div class="box-longin">
    <div class="container" [formGroup]="form">
        <div class="row justify-content-center">
            <div class="col-8">
                <div class="row">
                    <div class="col-12">
                        <h1>ลงทะเบียนเข้างาน</h1>
                        <br>
                    </div>
                    <div class="col-12">
                        <p>ชื่อ-นามสกุล <span style="color: red;">*</span></p>
                        <input type="text" class="form-control" formControlName="CustomerName">
                        <br>
                    </div>
                    <!-- <div class="col-12">
                        <p>ชื่อ <span style="color: red;">*</span></p>
                        <input type="text" class="form-control" formControlName="name1">
                        <br>
                    </div>
                    <div class="col-12">
                        <p>นามสกุล <span style="color: red;">*</span></p>
                        <input type="text" class="form-control" formControlName="name2">
                        <br>
                    </div> -->
                    <div class="col-12">
                        <p>โทร <span style="color: red;">*</span></p>
                        <input type="text" class="form-control" formControlName="PhoneNo" (keyup)="maxNumber(pn)" #pn>
                        <p style="color: red;">ตัวอย่าง 0991234567</p>
                        <br>
                    </div>
                    <div class="col-12">
                        <h3>โปรดเลือก</h3>
                        <br>
                    </div>
                    <div class="col-12">
                        <input type="checkbox" [checked]="form.value['CustomerIsGovernment']" (click)="checkUes(true)">
                        <span> เป็นกำลังพลกองทัพเรือ</span>
                        <br>
                        <br>
                        <!-- <input type="text" class="form-control" formControlName="CustomerRank">
                        <br> -->
                    </div>
                    <div class="col-12">
                        <input type="checkbox" [checked]="!form.value['CustomerIsGovernment']" (click)="checkUes(false)">
                        <span> อื่นๆ </span>
                        <br>
                        <br>
                    </div>
                    <div class="col-12 text-center">
                        <h5>*ข้อมูลนี้จะถูกเก็บเป็นความลับ*</h5>
                        <br>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 text-center">
                        <re-captcha (resolved)="resolved($event)" [siteKey]="domainKey">
                        </re-captcha>
                        <br><br><br>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <button class="btn btn-default btn-block" (click)="cancel()">ยกเลิก</button>
                    </div>
                    <div class="col-6">
                        <button class="btn btn-block" disabled *ngIf="!token">ลงทะเบียน</button>
                        <button class="btn btn-block" *ngIf="token" [disabled]="!token" [ngClass]="{'btn-primary':token}"
                            (click)="signin()">ลงทะเบียน</button>
                    </div>
                </div>
                <br><br><br>
            </div>
            <br><br><br>
        </div>
    </div>
</div>