import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { appUrl } from 'src/app/appUrl';

@Injectable({
  providedIn: 'root'
})
export class AutorizationGuard implements CanActivate {
  constructor(private app: AppService, private router: Router) { }
  canActivate(): boolean | Observable<boolean> {
    if (this.app.Authorization) return true;
    else { this.router.navigate(['/', appUrl.signin]); return false }
  }

}
