import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './component/home/home.component';
import { BoothComponent } from './component/booth/booth.component';
import { PanoramaComponent } from './component/panorama/panorama.component';
import { SharedsModule } from 'src/app/shareds/shareds.module';
import { StartPageComponent } from './component/start-page/start-page.component';
import { JointOrganizationComponent } from './component/joint-organization/joint-organization.component';
import { LearningComponent } from './component/learning/learning.component';
import { ScheduleComponent } from './component/schedule/schedule.component';
import { InquireComponent } from './component/inquire/inquire.component';
import { RecommendedComponent } from './component/recommended/recommended.component';
import { SigninComponent } from './component/signin/signin.component';
import { JointOrganization2Component } from './component/joint-organization2/joint-organization2.component';
import { PageWinComponent } from './component/page-win/page-win.component';



@NgModule({
  declarations: [
    HomeComponent,
    BoothComponent,
    PanoramaComponent,
    StartPageComponent,
    JointOrganizationComponent,
    LearningComponent,
    ScheduleComponent,
    InquireComponent,
    RecommendedComponent,
    SigninComponent,
    JointOrganization2Component,
    PageWinComponent
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    SharedsModule
  ],
  exports: [LearningComponent]

})
export class HomeModule { }
