import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { HttpService } from '../../services/http.service';
declare let $;
@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss']
})
export class QuizComponent implements OnInit {

  constructor(private http: HttpService,
    private primengConfig: PrimeNGConfig) { }

  itemQuiz: any = [];
  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.http.methodGet(`ms/quiz/find`).subscribe(res => {
      this.itemQuiz = res;
      this.itemQuiz.forEach(f => f['Answers'].forEach(sub => {
        sub.CustomerSelectQuizAnswerId = sub.QuizAnswerId;
      }));
    });
    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
  }

  openPopup = false;
  iconClose = 'assets/images/icon/Close.png';


  onOpen() {
    this.openPopup = true;
  }

  onClose() {
    this.openPopup = false;
  }

  ckitems(main, sub) {
    main['Answers'].forEach(f => f.SelectByCustomer = false);
    sub.SelectByCustomer = true;

  }

  sendItems: any[] = [];
  sendQuiz() {
    this.sendItems = [];
    this.itemQuiz.forEach(f => {
      if (f['Answers'].find(fn => fn.SelectByCustomer == true)) this.sendItems.push(f['Answers'].find(fn => fn.SelectByCustomer == true))
    });
    this.http.methodPost(`ms/quiz/create`, this.sendItems).subscribe(res => {
      alert('ส่งคำตอบแล้ว')
      this.onClose();
    }, err => alert(err.error.Message))
  }

}
