import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NumberService {

  constructor() { }

  formNumber(data: number|string) : string {
    let value: number;
    if (!data || (data && data === '')) return data+'';
    if (typeof(data) === 'string') {
      value = parseInt(data);
    } else {
      value = data;
    }

    if (value < 1000) return value+'';
    if (value <= 1000000) {
        value = value/1000;
        return (Math.floor(value * 100) / 100) + ' k';
    } else {
        value = value/1000000;
      return (Math.floor(value * 100) / 100) + ' m';
    }
  }

}
