<video class="d-none" controls id="video" style="position: absolute;">
    <source id="source" src="assets/file/welcome2.mp4" onended="End()" type="video/mp4">
    <!-- <source id="source" src="assets/file/welcome.mp4" onended="End()" type="video/mp4"> -->
</video>
<canvas id="canvas" class="position-absolute w-100 h-100 vdo-custom d-none" style="z-index: 1000;top: 0px;"
    [ngClass]="{'close-vdo': !vdo, 'd-none': !displayVdo}"></canvas>
<div class="vh-100" id="panorama"></div>
<app-stage *ngIf="Popup == -12" (status)="closePopup()"></app-stage>
<app-info *ngIf="Popup == -11" (status)="closePopup()"></app-info>
<app-chat></app-chat>
<app-quiz></app-quiz>
