<div class="box-quiz" (click)="onOpen()">
    <!-- <p>กิจกรรม</p> -->
    <img src="../../../../assets/images/icon/gk.png" alt="" data-toggle="tooltip" data-placement="bottom" title="คลิ๊กเพื่อเล่นกิจกรรม">
</div>

<p-dialog header="" [(visible)]="openPopup" [modal]="true" [style]="{width: '60vw'}" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false" (onHide)="onClose()" [styleClass]="'faq'" [showHeader]="false">
    <img class="icon-close" [src]="iconClose" alt="icon-close" (click)="onClose()">
    <div class="row  justify-content-center">
        <div class="col-8">
            <div class="row">
                <div class="col-12 text-center">
                    <h1>กิจกรรมตอบคำถาม</h1>
                    <br>
                </div>
                <ng-container *ngFor="let qz of itemQuiz">
                    <div class="col-12">
                        <hr>
                        <h3>คำถาม {{qz.QuizQuestionInfo}}</h3>
                        <hr>
                        <h4>คำตอบ</h4>
                    </div>
                    <div class="col-12" *ngFor="let aw of qz.Answers">
                        <h6 class="box-k" (click)="ckitems(qz,aw)"> <input type="checkbox" [checked]="aw.SelectByCustomer">
                            {{aw.QuizAnswerInfo}}</h6>
                    </div>

                </ng-container>
            </div>
            <br>
            <div class="row">
                <div class="col-12">
                    <button class="btn btn-primary btn-block" (click)="sendQuiz()">ส่งคำตอบ</button>
                </div>
            </div>
            <br><br>
        </div>
    </div>
</p-dialog>