<p-dialog header="" [(visible)]="openPopup" [modal]="true" [style]="{width: '60vw'}" [baseZIndex]="10000" [draggable]="false"
    [resizable]="false" (onHide)="onClose()" [styleClass]="'faq'" [showHeader]="false">
    <img class="icon-close" [src]="iconClose" alt="icon-close" (click)="onClose()">
    <div class="row">
        <div class="col-12">
            <h1 class="text-center">กิจกรรมกองทัพเรือ</h1>
            <br>
            <br>
            <ng-container *ngFor="let vdo of itemsVdo">
                <h3 class="text-center">
                    <p> {{vdo.SettingDataName}}</p>
                    <br>
                    <div style="position: relative; padding-top: 56.25%;">
                        <div [innerHTML]="vdo.SettingDataValue"></div>
                    </div>
                </h3>
                <br><br>
            </ng-container>
            <!-- <h3 class="text-center">
                <p> วันที่ 10 สิงหาคม 2564</p>
                <br>
                <div style="position: relative; padding-top: 56.25%;"><iframe src="https://iframe.videodelivery.net/489cd94c376c1dfb878159d67cc85485?loop=true&autoplay=true"
                        style="border: none; position: absolute; top: 0; height: 100%; width: 100%; left:0px; right: 0px;"
                        allow="accelerometer; gyroscope; encrypted-media; picture-in-picture;" allowfullscreen="true"></iframe></div>
            </h3> -->
            <!-- <br><br>
            <h3 class="text-center">
                <p> วันที่ 11 สิงหาคม 2564</p>
                <br>
                <div style="position: relative; padding-top: 56.25%;"><iframe src="https://drive.google.com/file/d/1-uZO2lSV7SHktcDFDLfFmIohC4JOyoKH/preview"
                        style="border: none; position: absolute; top: 0; height: 100%; width: 100%; left:0px; right: 0px;"></iframe></div>
            </h3>
            <br><br>
            <h3 class="text-center">
                <p> วันที่ 12 สิงหาคม 2564</p>
                <br>
                <div style="position: relative; padding-top: 56.25%;"><iframe src="https://drive.google.com/file/d/13rXAGSZMoIXUGA__CE9y7VYNzmynsc4u/preview"
                        style="border: none; position: absolute; top: 0; height: 100%; width: 100%; left:0px; right: 0px;"></iframe></div>
            </h3>
            <br><br> -->
        </div>
    </div>
    <br>
    <br>
    <div class="row">
        <div class="col-12">
            <h1 class="text-center">ภาพกิจกรรม</h1>
            <br>
            <div class="swiper-container mySwiper">
                <div class="swiper-wrapper">
                    <div *ngFor="let img of imgSlide; let index = index" class="swiper-slide">
                        <img width="100%" [src]="img" alt="">
                    </div>
                </div>
                <div class="swiper-button-next"></div>
                <div class="swiper-button-prev"></div>
                <div class="swiper-pagination"></div>
            </div>
        </div>
    </div>
</p-dialog>