import { LoadingService } from './../../../service/loading.service';
import { FAQ } from './../../../modules/home/interface/faq.interface';
import { BoothService } from './../../../service/booth.service';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { AlertService } from 'src/app/service/alert.service';
import { AlertInterface } from '../../interface/alert.interface';


@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  @Output() status = new EventEmitter<boolean>();
  @Input() boothId: number;
  @Input() set setData(data: any) {
    if (data) {
      this.loadingService.changeCount(1);
      this.openPopup = true;
      setTimeout(() => {
        this.boothService.getFAQ(this.boothId).subscribe(
          (res: FAQ[]) => {
            this.faq = res;

            if (this.faq && this.faq.length > 0) {
              this.displayFAQ = [];
              this.faq.forEach( f => this.displayFAQ.push(false));
              this.displayFAQ[0] = true;
            }            
            this.loadingService.resetCount();
          }, err => {
            let dataAlert: AlertInterface;
            dataAlert = { severity: 'error', title: 'error', detail: err.message }
            this.alertSer.setAlert(dataAlert);
          }
        );
      },500 );
    }
  }
  
  openPopup = false;
  iconOperator = 'assets/images/icon/operator.png';
  iconClose = 'assets/images/icon/Close.png'; 
  iconChat = 'assets/images/icon/FAQ.png';
  faq: FAQ[];
  displayFAQ = [];
  blockIn = false;


  constructor(
    private primengConfig: PrimeNGConfig,
    private boothService: BoothService,
    private loadingService: LoadingService,
    private alertSer: AlertService,
    ) { }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
  }

  resetClass(id: string) {
    setTimeout(() => {
      let element = document.getElementById(id);
      element.classList.remove('in');
    }, 500);
  }

  onOpen() {
    this.openPopup = true;
  }

  openChat() {
    window.open('https://page.line.me/211topar');
  }

  onClose() {
    this.openPopup = false;
    this.status.emit(false);
  }

}
