import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { NavServiceService } from '../../../modules/home/service/nav-service.service';
import { LoadingService } from '../../../service/loading.service';
declare let Swiper;
@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {
  @Output() status = new EventEmitter<boolean>();

  openPopup = true;
  iconClose = 'assets/images/icon/Close.png';


  constructor(
    private navService: NavServiceService,
    private loadingService: LoadingService
  ) { }

  ngOnInit(): void {
    this.navService.showNavbar();
    this.loadingService.changeCount(1);
    setTimeout(() => {
      this.setSwiper();
    }, 500);
    setTimeout(() => {
      this.loadingService.resetCount();

      document.getElementsByClassName("swiper-button-next")[0].classList.add('fix-custom-next');
      document.getElementsByClassName("swiper-button-prev")[0].classList.add('fix-custom-prev');
    }, 2000);


  }

  onClose() {
    this.openPopup = false;
    this.status.emit(false);
  }



  path = 'assets/images/learning/';
  // img = [
  //   `${this.path}data6.png`,
  //   `${this.path}data1.png`,
  //   `${this.path}data2.png`,
  //   `${this.path}data4.png`,
  //   `${this.path}data3.png`,
  //   `${this.path}data5.png`,
  // ];
  img = [
    `${this.path}d1.png`,
    `${this.path}d2.png`,
    `${this.path}d3.png`,
    `${this.path}d4.png`,
    `${this.path}d5.png`,
    `${this.path}d6.png`,
    `${this.path}d7.png`,
    `${this.path}d8.png`,
  ];


  setSwiper() {
    let swiper = new Swiper(".mySwiper", {
      pagination: {
        el: ".swiper-pagination",
        type: "progressbar",
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  }

}
