import { Component, OnInit } from '@angular/core';
import { NavServiceService } from '../../service/nav-service.service';

@Component({
  selector: 'app-joint-organization2',
  templateUrl: './joint-organization2.component.html',
  styleUrls: ['./joint-organization2.component.scss']
})
export class JointOrganization2Component implements OnInit {

  path = `assets/images/icon/`;
  items = [
    {
      name: "หน่วยงานทางการทหาร",
      items: [
        {
          id: 22,
          img: [
            {
              src: `${this.path}ncit.png`,
              class: 'fix-1'
            }
          ],
          name: "กรมการสื่อสารและเทคโนโลยีสารสนเทศทหารเรือ",
          type: "Organizer",
          // product: [
          //   " - ระบบเฝ้าตรวจแจ้งเตือนด้วยเทคโนโลยีปัญญาประดิษฐ์"
          // ],
          text: [
            "ที่อยู่ พระราชวังเดิม ถ.วังเดิม แขวงวัดอรุณ เขตบางกอกใหญ่ กรุงเทพฯ 10600",
            "โทร 02-475-7700, 02-475-7600",
          ],
          email: "webmaster@navy.mi.th",
          website: ["http://www.ncit.navy.mi.th"],
        },

        {
          id: 13,
          img: [
            {
              src: `${this.path}tr.png`,
              class: ''
            }
          ],
          name: "กรมข่าวทหารเรือ",
          type: "AI",
          product: [
            "- ระบบรักษาความปลอดภัยและเฝ้าติดตามสถานการณ์ด้วยกลไกปัญญาประดิษฐ์ (Artificial Intelligence for Security)"
          ],
          text: [
            "ที่อยู่ กองบัญชาการกองทัพเรือ (วังนันทอุทยาน) เขตบางกอกน้อย กรุงเทพฯ 10700",
            "โทร 0-2475-4680 แฟกซ์ 0-2475-5571",
          ],
          email: "nid.rtn@gmail.com",
          website: ["http://www.n2.navy.mi.th"],
        },
        {
          id: 14,
          img: [
            {
              src: `${this.path}us.png`,
              class: ''
            },
            {
              src: `${this.path}us2.png`,
              class: 'fix-1'
            }
          ],
          name: "กรมอุทกศาสตร์",
          type: "Another",
          product: [
            "- ระบบแสดงแผนที่อิเล็กทรอนิกส์ (Electronic Chart System)"
          ],
          text: [
            "ที่อยู่ กองบัญชาการกองทัพเรือ (วังนันทอุทยาน) เขตบางกอกน้อย กรุงเทพฯ 10700",
            "โทร 02-7354435",
          ],
          email: "hydrotech@navy.mi.th",
          website: ["http://www.hydro.navy.mi.th"],
        },
        {
          id: 16,
          img: [
            {
              src: `${this.path}pt.png`,
              class: 'fix-1'
            }
          ],
          name: "สำนักงานวิจัยและพัฒนาการทางทหารกองทัพเรือ",
          type: "AI",
          product: [
            "- ระบบเฝ้าตรวจแจ้งเตือนด้วยเทคโนโลยีปัญญาประดิษฐ์"
          ],
          text: [
            "ที่อยู่ เลขที่ 83 ถนนพุทธมณฑลสาย 3 แขวงศาลาธรรมสพน์ เขตทวีวัฒนา กรุงเทพฯ 10170",
            "โทร 02-475-7205",
          ],
          email: "nrdotech@navy.mi.th",
          website: ["http://www.nrdo.navy.mi.th"],
        },
        {
          id: 15,
          img: [
            {
              src: `${this.path}wt.jpg`,
              class: ''
            }
          ],
          name: "กรมวิทยาศาสตร์ทหารเรือ",
          type: "Another",
          product: [
            "- ยานพิสูจน์ทราบสารพิษ (เสือดำ 2)",
          ],
          text: [
            "ที่อยู่  83 หมู่ 12 พุทธมณฑลสาย 3 เขตทวีวัฒนา กรุงเทพฯ  10170",
            "โทร 0-2475-7104 แฟกซ์ 0-2475-7138",
          ],
          email: "prnavalscience@gmail.com , prnavalscience@gmail.com",
          website: ["http://www2.science.navy.mi.th"],
        },
        {
          id: 12,
          img: [
            {
              src: `${this.path}ra.png`,
              class: 'fix-2'
            },
            {
              src: `${this.path}ra2.png`,
              class: 'fix-2'
            }
          ],
          name: "โรงเรียนนายเรืออากาศนวมินทกษัตริยาธิราช",
          type: "AI",
          product: [
            "- NKRAFA Smart Security Campus",
          ],
          text: [
            "ที่อยู่  171/1 ถนนพหลโยธิน เขตสายไหม กรุงเทพฯ 10220",
            "โทร 02-534-3624-6, 02-534-3618",
          ],
          email: "stat.rtafa@gmail.com",
          website: ["http://www.nkrafa.ac.th"],
        },
      ]
    },
    {
      name: "หน่วยงานทางการศึกษา",
      items: [
        {
          id: 11,
          img: [
            {
              src: `${this.path}jr.png`,
              class: 'fix-1'
            }
          ],
          name: "คณะวิศวกรรมศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย",
          type: "AI",
          product: [
            "- ผลงานวิจัยด้าน AR/VR และเทคโนโลยี 5G",
          ],
          text: [
            "ที่อยู่ ถนนพญาไท แขวงวังใหม่ เขตปทุมวัน กรุงเทพฯ 10330",
            "โทร 0626128816",
          ],
          email: "Lunchakorn.W@chula.ac.th",
          website: ["https://www.chula.ac.th/"],
        },
        {
          id: 19,
          img: [
            {
              src: `${this.path}utc.png`,
              class: 'fix-2'
            }
          ],
          name: "ศูนย์วิจัยและพัฒนาเทคโนโลยีเพื่ออุตสาหกรรม จุฬาลงกรณ์มหาวิทยาลัย",
          type: "AI",
          product: [
            "- Chula UTC AI Innovation Ecosystem",
          ],
          text: [
            "ที่อยู่ อาคารจามจุรี 10, ชั้น 3 ห้องเลขที่ 313 ถนนพญาไท ปทุมวัน",
            "กรุงเทพฯ  10330",
            "โทร 089-940-3241",
          ],
          email: "utc@chula.ac.th",
          website: ["https://www.cutechcenter.com"],
        },
        {
          id: 17,
          img: [
            {
              src: `${this.path}me2.png`,
              class: 'fix-1'
            }
          ],
          name: "คณะวิศวกรรมศาสตร์ มหาวิทยาลัยมหิดล",
          type: "AI",
          product: [
            "- หุ่นยนต์ AI ช่วยในการผ่าตัด",
          ],
          text: [
            "ที่อยู่ 25/25 ถ.พุทธมณฑลสาย 4, ศาลายา,พุทธมณฑล, นครปฐม, 73170 ",
            "โทร 0 2889 2138 แฟกซ์  0 2441 973",
          ],
          email: "engineering@mahidol.ac.th",
          website: ["https://www.eg.mahidol.ac.th"],
        },
        {
          id: 18,
          img: [
            {
              src: `${this.path}pj.png`,
              class: 'fix-1'
            }
          ],
          name: "มหาวิทยาลัยเทคโนโลยีพระจอมเกล้าพระนครเหนือ",
          type: "AI",
          product: [
            " - EEG Controlled Robot for Supporting Bedfast Patient",
          ],
          text: [
            "ที่อยู่ 1518 ถนนประชาราษฎร์ 1 แขวงวงศ์สว่าง เขตบางซื่อ กรุงเทพฯ 10800 ",
            "โทร +66 2 555-2000  แฟกซ์ +66 2 587-4350",
          ],
          email: "contact@op.kmutnb.ac.th",
          website: ["https://www.kmutnb.ac.th"],
        },
      ]
    },
    {
      name: "หน่วยงานภาคเอกชน",
      items: [
        {
          id: 20,
          img: [
            {
              src: `${this.path}tangerine2.png`,
              class: ''
            }
          ],
          name: "บริษัท แทนเจอรีน จำกัด ",
          type: "AI",
          product: [
            "– Smart Business Analytics",
          ],
          text: [
            "ที่อยู่ ชั้น 23 อาคารกรุงเทพประกันภัย 25 ถนนสาทรใต้ ทุ่งมหาเมฆ สาทร กรุงเทพฯ 10120",
            "โทร +66 2 285 5511",
          ],
          email: "info@tangerine.co.th",
          website: ["https://www.tangerine.co.th"],
        },
        {
          id: 4,
          img: [
            {
              src: `${this.path}backyard.png`,
              class: ''
            }
          ],
          name: "บริษัท แบ็คยาร์ด จำกัด",
          type: "AI & Big Data",
          product: [
            "- การวิเคราะห์ข้อมูลขนาดใหญ่ด้วยปัญญาประดิษฐ์และการนำไปใช้",
          ],
          text: [
            "ที่อยู่ ชั้น 3-4 อาคารโมบิคอม 4 95 ซอยพระพินิจ ถ.นราธิวาสราช",
            "นครินทร์ ทุ่งมหาเมฆ สาทร กรุงเทพฯ 10120 ประเทศไทย ",
            "โทร (+66)2-054-6993 ",
          ],
          email: "info@backyard.in.th",
          website: ["https://www.backyard.in.th"],
        },
        {
          id: 5,
          img: [
            {
              src: `${this.path}ztrus.png`,
              class: ''
            }
          ],
          name: "บริษัท แอ็คโคเมท จำกัด",
          type: "AI",
          product: [
            "- การให้บริการด้านการแปลงข้อมูลสู่ดิจิตอล ด้วย OCR และ AI ทั้งภาษาไทย และภาษาอังกฤษ (Ztrus : Document Automation)",
          ],
          text: [
            "ที่อยู่  เลขที่ 484/31 ซอยเพชรบุรี 18 ถนนเพชรบุรี แขวงถนนเพชรบุรี เขตราชเทวี กรุงเทพฯ  10400",
            "โทร +66 (0) 65 474 4665",
          ],
          email: "info@backyard.in.sale@ztrus.com",
          website: ["https://www.ztrus.com"],
        },
        {
          id: 6,
          img: [
            {
              src: `${this.path}hg.png`,
              class: 'fix-2'
            },
          ],
          name: "บริษัท เอชจี โรโบติกส์ จำกัด",
          type: "AI",
          product: [
            "- ยานพาหนะไร้คนขับ (Unmanned Vehicle)",
          ],
          text: [
            "ที่อยู่  254 อาคารวิจัยจุฬาลงกรณ์ ชั้น 14 ถนนพญาไท วังใหม่ ปทุมวัน",
            "กรุงเทพฯ 10330",
            "โทร 02-1634654 ",
          ],
          email: "info@hiveground.com",
          website: ["https://www.hiveground.com"],
        },
        {
          id: 6,
          img: [
            {
              src: `${this.path}hg2.png`,
              class: ''
            }
          ],
          name: "บริษัท มาร์ซัน จำกัด (มหาชน) ",
          type: "AI",
          product: [
            "- ยานพาหนะไร้คนขับ (Unmanned Vehicle)",
          ],
          text: ['ที่อยู่ 413 ซอยสิทธิไชย ถนนท้ายบ้าน ตำบลท้ายบ้าน อำเภอเมืองสมุทรปราการ',
            ' จังหวัดสมุทรปราการ 10280',
            'โทร 02-1738942',
          ],
          email: "contact@marsun.th.com",
          website: ["http://www.marsun.th.com"],
        },
        {
          id: 8,
          img: [
            {
              src: `${this.path}huawei2.png`,
              class: ''
            }
          ],
          name: "บริษัท หัวเว่ยเทคโนโลยี่ จำกัด",
          type: "AI",
          product: [
            "- Huawei Intelligent Collaboration Solution",
          ],
          text: [
            "ที่อยู่  ออลซีซั่น เพลส, แคพพิเทิล ทาวเวอร์ ชั้น 19 87/1 ถนนวิทยุ  ",
            "แขวงลุมพินี  เขตปทุมวัน กรุงเทพฯ  10330",
            "โทร 02-0958199",
          ],
          email: "Enterprise_thailand@huawei.com",
          website: ["https://www.huawei.com/th"],
        },
        {
          id: 3,
          img: [
            {
              src: `${this.path}wisesight.png`,
              class: ''
            }
          ],
          name: "บริษัท ไวซ์ไซท์ (ประเทศไทย) จำกัด",
          type: "AI & Big Data",
          product: [
            "- การวิเคราะห์ข้อมูลบนโลกโซเชียลมีเดีย (Social Media and Big Data)",
          ],
          text: [
            "ที่อยู่  123 อาคารซันทาวเวอร์ส บี ชั้น 33 ถ.วิภาวดีรังสิต จอมพล จตุจักร",
            "กรุงเทพฯ 10900 ประเทศไทย",
            "โทร +66 (2) 274 1299",
          ],
          email: "contact@wisesight.com",
          website: ["https://wisesight.com"],
        },
        {
          id: 2,
          img: [
            {
              src: `${this.path}iKl.png`,
              class: 'fix-1'
            }
          ],
          name: "บริษัท ไอโนว์ อินฟอร์เมชั่น จำกัด",
          type: "AI",
          product: [
            "- งานแปลงเอกสารกระดาษเป็นฐานข้อมูลด้วย AI-OCR",
            "- เทคโนโลยี AI ที่เกี่ยวข้องกับทหารเรือ",
          ],
          text: [
            "ที่อยู่  223 ถนนสุนทรโกษา คลองเตย เขตคลองเตย กรุงเทพฯ ",
            "โทร 0814848483",
          ],
          email: "info@swinno.com",
          website: ["http://www.swinno.com "],
        },
        {
          id: 1,
          img: [
            {
              src: `${this.path}app.png`,
              class: 'fix-1'
            }
          ],
          name: "บริษัท ไอแอพพ์เทคโนโลยี จำกัด",
          type: "AI",
          product: [
            " - หุ่นยนต์ต้อนรับและสนทนา",
            " - ระบบกล้องรายงานผู้สวมหน้ากากป้องกันโควิค",
            " - ระบบปัญญาประดิษฐ์ภาษาไทย",
          ],
          text: [
            "ที่อยู่  80/359 หมู่ 3 ซอยคลองหลวง 26 คลองหนึ่ง คลองหลวง ปทุมธานี",
            "12120",
            "โทร 02-329-5997",
          ],
          email: "info@iapp.co.th",
          website: ["https://iapp.co.th"],
        },
        {
          id: 7,
          img: [
            {
              src: `${this.path}aimagin.png`,
              class: ''
            }
          ],
          name: "บริษัท เอมเมจิน จำกัด",
          type: "AI & Big Data",
          product: [
            " - ระบบฐานข้อมูลขนาดใหญ่ (Big Data) ศรชล.",
          ],
          text: [
            "ที่อยู่ 555/387 หมู่ 5 ต.บางเมือง อ.เมืองสมุทรปราการ สมุทรปราการ 10270",
            "โทร 093-039-2700, 086-246-2446 ",
          ],
          email: "sales@aimagin.com",
          website: ["https://www.aimagin.com"],
        },
        {
          id: 10,
          img: [
            {
              src: `${this.path}eci.jpeg`,
              class: ''
            }
          ],
          name: "บริษัท อี แอนด์ ซี อินเตอร์เน็ทเวิร์ค จำกัด",
          type: "AI",
          product: [
            " - ระบบการรักษาความปลอดภัยและระบบสื่อสาร",
          ],
          text: [
            "ที่อยู่ 315/65 หมู่ที่ 1 ต.แหลมฟ้าผ่า อ.พระสมุทรเจดีย์ ",
            "จ.สมุทรปราการ 10290",
            "โทร 02-7318888",
          ],
          email: "pravit.chaipotong@th.panasonic.com",
          website: ["https://www.ecinternetwork.com "],
        },
        {
          id: 666,
          img: [
            {
              src: `${this.path}ggb.png`,
              class: ''
            }
          ],
          name: "บริษัท เบอร์รี่ บี กรุ๊ป จำกัด",
          type: "Another (ผู้พัฒนาระบบเว็บนิทรรศการออนไลน์ หรือ Virtual Exhibition Website)",
          product: [
            " - All IT Solution and High Technology Equipment, Medical",
            " - Equipment and Cosmetic, Digital Marketing, Media and Organizer, Importer and Exporter",
          ],
          text: [
            "266/4 ถนนพหลโยธิน เเขวงอนุสาวรีย์ เขตบางเขน กรุงเทพมหานคร 10220",
            "Tel: 092-247-9741, 094-496-6547, 061-524-1661| Line id: @berryb",
          ],
          email: "admin@berryb.co.th",
          website: ["https://www.berryb.co.th"],
          websiteText: ""
        },
      ]
    }
  ];

  constructor(
    private navService: NavServiceService,
  ) { }

  ngOnInit(): void {
    this.navService.showNavbar();
  }

}
