import { ScheduleComponent } from './component/schedule/schedule.component';
import { LearningComponent } from './component/learning/learning.component';
import { JointOrganizationComponent } from './component/joint-organization/joint-organization.component';
import { StartPageComponent } from './component/start-page/start-page.component';
import { PanoramaComponent } from './component/panorama/panorama.component';
import { BoothComponent } from './component/booth/booth.component';
import { HomeComponent } from './component/home/home.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InquireComponent } from './component/inquire/inquire.component';
import { RecommendedComponent } from './component/recommended/recommended.component';
import { AutorizationGuard } from './../guards/autorization.guard';
import { appUrl } from 'src/app/appUrl';
import { SigninComponent } from './component/signin/signin.component';
import { JointOrganization2Component } from './component/joint-organization2/joint-organization2.component';
import { PageWinComponent } from './component/page-win/page-win.component';


const routes: Routes = [
  { path: '', redirectTo: 'start-page', pathMatch: 'full' },
  { path: 'start-page', component: StartPageComponent },
  { path: 'home/:status', component: PanoramaComponent, canActivate: [AutorizationGuard] },
  { path: ':path/booth/:id', component: BoothComponent, canActivate: [AutorizationGuard] },
  { path: 'walk', component: HomeComponent },
  { path: 'joint-organization', component: JointOrganization2Component },
  { path: 'learning/:index', component: LearningComponent },
  { path: 'learning', component: LearningComponent },
  { path: 'schedule', component: ScheduleComponent },
  { path: 'inquire', component: InquireComponent },
  { path: 'recommended', component: RecommendedComponent },
  { path: 'winner', component: PageWinComponent },
  { path: appUrl.signin, component: SigninComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
