import { LoadingService } from './../../../../service/loading.service';
import { NavServiceService } from './../../service/nav-service.service';
import { Router } from '@angular/router';
import { Component, OnInit, HostListener } from '@angular/core';
import { AppService } from 'src/app/app.service';
declare let Swiper;


@Component({
  selector: 'app-start-page',
  templateUrl: './start-page.component.html',
  styleUrls: ['./start-page.component.scss']
})
export class StartPageComponent implements OnInit {

  path = `assets/images/startPage/`;
  path2 = `assets/images/icon/`;
  background1 = `${this.path}GateV2-Edit.png`
  background2 = `${this.path}background2.png`
  logo = `${this.path}logos.png`
  iconLogo = [
    `${this.path2}ncit.png`,
    `${this.path2}pt.png`,
    `${this.path2}tr.png`,
    `${this.path2}us.png`,
    `${this.path2}us2.png`,
    `${this.path2}wt.png`,
    `${this.path2}ra.png`,
    `${this.path2}ra2.png`,
    `${this.path2}jr.png`,
    `${this.path2}utc.png`,
    `${this.path2}me3.png`,
    `${this.path2}pj2.png`,
    `${this.path2}tangerine2.png`,
    `${this.path2}backyard.png`,
    `${this.path2}ztrus.png`,
    `${this.path2}hg.png`,
    `${this.path2}hg2.png`,
    `${this.path2}huawei3.png`,
    `${this.path2}wisesight3.png`,
    `${this.path2}iKl.png`,
    `${this.path2}app.png`,
    `${this.path2}aimagin.png`,
    `${this.path2}eci.jpeg`,
    `${this.path2}ggb.png`,
  ];
  days = 0;
  hours = 0;
  minutes = 0;
  second = 0;
  closeDetail = false;
  swiper: any;

  setWidth = 1920;
  setHeight = 970;

  // new function date 06/08 
  pathImg = `assets/images/`;
  imgAdvert = [
    `${this.pathImg}/index.jpg`,
    `${this.pathImg}/vision.jpg`,
  ];
  swiperAdvert: any;
  
  displayTime = true;

  innerWidth = 0;
  btnData = [
    {
      id: 0,
      topPersen: 74.3444329897,
      leftPersen: 8.57994791667,
    },
    {
      id: 2,
      topPersen: 70.942408377,
      leftPersen: 19.3611979167,
    },
    {
      id: 4,
      topPersen: 70.942408377,
      leftPersen: 80.03828125,
    },
    {
      id: 6,
      topPersen: 74.3444329897,
      leftPersen: 91.3403645833,
    },
  ];

  constructor(
    private router: Router,
    private navService: NavServiceService,
    private loadingService: LoadingService,
    private app: AppService
  ) {
    this.onCountdown();
  }

  ngOnInit(): void {
    // this.navService.closeNavbar();
    this.loadingService.changeCount(1);
    this.navService.showNavbar();
    setTimeout(() => {
      this.setSwiper();
      this.loadingService.resetCount();
    }, 500);

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.loadingService.changeCount(1);
    setTimeout(() => {
      this.setSwiper();
      this.loadingService.resetCount();
    }, 500);
  }

  onCountdown() {
    let countDownDate = new Date("Aug 10, 2021 08:00:00").getTime();
    let count = setInterval(() => {
      let dateNow = new Date().getTime();
      let distance = countDownDate - dateNow;
      this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
      this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      this.second = Math.floor((distance % (1000 * 60)) / 1000);
      this.displayTime = (this.days + this.hours + this.minutes + this.second > 0);
      if (!this.displayTime) {
        clearInterval(count);
      }
    });
  }

  onCloseDetail() {
    // return;
    this.closeDetail = true;
  }

  setSwiper() {
    if (this.swiper) this.swiper.destroy(true, true);
    if (this.swiperAdvert) this.swiperAdvert.destroy(true, true);
    let countItem = 5;
    if (window.innerWidth < 680) {
      countItem = 3;
    } else if (window.innerWidth < 970) {
      countItem = 4;
    } else if (window.innerWidth < 1140) {
      countItem = 5;
    } else if (window.innerWidth < 1330) {
      countItem = 5;
    } else if (window.innerWidth < 1560) {
      countItem = 5;
    }
    this.swiper = new Swiper(".mySwiper", {
      slidesPerView: countItem,
      spaceBetween: 30,
      // slidesPerGroup: 7,
      speed: 1000,
      loop: true,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".swiper-pagination",
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });

    if (!this.displayTime) {
      // swiperAdvert
      // this.swiperAdvert = new Swiper(".swiperAdvert", {
      //   spaceBetween: 30,
      //   speed: 1500,
      //   loop: true,
      //   autoplay: {
      //     delay: 6000,
      //     disableOnInteraction: false,
      //   },
      //   pagination: {
      //     el: ".swiper-pagination",
      //   },
      //   navigation: {
      //     nextEl: ".swiper-button-next",
      //     prevEl: ".swiper-button-prev",
      //   },
      // });
    }

    // func img 
    let bgImg = document.getElementById('background1');
    let btn = document.getElementById('component-btn');
    // let component = document.getElementById('component');
    let innerHeight = window.innerHeight;
    let innerWidth = window.innerWidth;
    this.setWidth = 1920;
    this.setHeight = 970;
    if (bgImg) {
      if (innerHeight != 970) {
        this.setWidth = (innerHeight * this.setWidth) / this.setHeight;
        this.setHeight = innerHeight;
      }
      if (innerWidth > this.setWidth) {
        this.setHeight = (innerWidth * this.setHeight) / this.setWidth;
        this.setWidth = innerWidth;
      }
      bgImg.style.height = this.setHeight + 'px';
      bgImg.style.width = this.setWidth + 'px';

      // set size component btn-data
      this.innerWidth = window.innerWidth;
      let compoData = document.getElementById('compo-btn-data');
      compoData.style.height = this.setHeight + 'px';
      compoData.style.width = this.setWidth + 'px';
      
      // set tranform
      if (this.setWidth > window.innerWidth) {
        bgImg.style.transform = "translateX(" + ((window.innerWidth - this.setWidth) / 2) + "px)";
      }
      btn.style.top = `${(this.setHeight / 100) * 52.5773195876}px`;
    }

  }

  signin: boolean = false;
  paths: string;
  nextPage(path: string) {
    this.router.navigateByUrl(path);
  }

}
