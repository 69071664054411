import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SharedsRoutingModule } from './shareds-routing.module';
import { NavBarComponent } from './component/nav-bar/nav-bar.component';
import { FaqComponent } from './component/faq/faq.component';
import { DialogModule } from 'primeng/dialog';
import { VideoComponent } from './component/video/video.component';
import { ProductComponent } from './component/product/product.component';
import { LiveComponent } from './component/live/live.component';
import { StreamingComponent } from './component/live/streaming/streaming.component';
import { ChatComponent } from './component/chat/chat.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { StageComponent } from './component/stage/stage.component';
import { Product2Component } from './component/product2/product2.component';
import { InfoComponent } from './component/info/info.component';
import { HomeModule } from '../modules/home/home.module';
import { LearningComponent } from '../modules/home/component/learning/learning.component';
import { LoginComponent } from './component/login/login.component';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { QuizComponent } from './component/quiz/quiz.component';
import {ToastModule} from 'primeng/toast';
import { TabViewModule } from 'primeng/tabview';


@NgModule({
  declarations: [
    NavBarComponent,
    FaqComponent,
    VideoComponent,
    ProductComponent,
    LiveComponent,
    StreamingComponent,
    ChatComponent,
    StageComponent,
    Product2Component,
    InfoComponent,
    LoginComponent,
    QuizComponent,

  ],
  imports: [
    CommonModule,
    SharedsRoutingModule,
    ProgressSpinnerModule,
    ReactiveFormsModule,
    HttpClientModule,
    DialogModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    ToastModule,
    TabViewModule,
  ],
  exports: [
    NavBarComponent,
    FaqComponent,
    VideoComponent,
    ProductComponent,
    ProgressSpinnerModule,
    DialogModule,
    LiveComponent,
    ChatComponent,
    StageComponent,
    Product2Component,
    InfoComponent,
    LoginComponent,
    QuizComponent,
    ToastModule,
    TabViewModule,
  ]
})
export class SharedsModule { }
