import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NavServiceService } from 'src/app/modules/home/service/nav-service.service';
import { HttpService } from 'src/app/shareds/services/http.service';
declare let Twitch;
@Component({
  selector: 'app-streaming',
  templateUrl: './streaming.component.html',
  styleUrls: ['./streaming.component.scss']
})
export class StreamingComponent implements OnInit {

  constructor(private navService: NavServiceService,
    private router: Router,
    private http: HttpService,
    private sanitizer: DomSanitizer) { }
  //
  live1: boolean = false;
  ngOnInit(): void {
    this.navService.showNavbar();
    this.getItemsLive();
    setInterval(res => {
      if (!this.live1) if (document.getElementById('twitch-embed1')) {
        new Twitch.Player("twitch-embed1", {
          channel: "navyvrmain",
          width: 500,
          height: 500,
          parent: ['thainavy.org', 'นิทรรศการกองทัพเรือ.com']
        });
        this.live1 = true;
      }
    }, 1500)


    // new Twitch.Player("twitch-embed2", {
    //   channel: "navyvrroom1",
    //   width: 500,
    //   height: 500,
    //   parent: ['thainavy.org', 'นิทรรศการกองทัพเรือ.com']
    // });

    // new Twitch.Player("twitch-embed3", {
    //   channel: "navyvrroom2",
    //   width: 500,
    //   height: 500,
    //   parent: ['thainavy.org', 'นิทรรศการกองทัพเรือ.com']
    // });

    // new Twitch.Player("twitch-embed4", {
    //   channel: "navyvrroom3",
    //   width: 500,
    //   height: 500,
    //   parent: ['thainavy.org', 'นิทรรศการกองทัพเรือ.com']
    // });

  }

  itemsLive: any = [];
  getItemsLive() {
    this.http.methodGet(`ms/livestream`).subscribe(res => {
      this.itemsLive = res;
      this.itemsLive.forEach(f => {
        f.VideoPlayBackLink = this.sanitizer.bypassSecurityTrustHtml(f.VideoPlayBackLink)
      });
    })
  }

  backurl() {
    this.router.navigate(['/'])
  }
}


