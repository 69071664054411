import { BoothService } from './../../../service/booth.service';
import { LoadingService } from './../../../service/loading.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {
  @Output() popup = new EventEmitter<boolean>();
  @Input() linkVideo: string;
  @Input() set setData(data: any) {
    this.loadingService.changeCount(1);
    if (data) {
      this.openPopup = true;
      setTimeout(() => {
        this.loadingService.resetCount();
        if (this.linkVideo) {
          this.typeVDO = this.linkVideo.includes('video.thainavy.org');
          if (this.typeVDO) {
            let video: any = document.getElementById('video');
            video.src = this.linkVideo;
            video.load();
            video.play();
          } else {
            let iframe: any = document.getElementById('iframe');
            iframe.src = `https://${this.linkVideo.split('http://')[1]}`;
          }
        }
      }, 500);
    }
  }

  openPopup: boolean = false;
  typeVDO = false;

  constructor(
    private primengConfig: PrimeNGConfig,
    private loadingService: LoadingService,
    private boothService: BoothService,
  ) {
  }


  ngOnInit(): void {
    this.primengConfig.ripple = true;
  }
  onOpen() {
    this.openPopup = true;
  }

  onClose() {
    this.openPopup = false;
    this.popup.emit(false);
  }

}
