<p-dialog header="" [(visible)]="openPopup" [modal]="true" [style]="{width: '60vw'}" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false" (onHide)="onClose()" [styleClass]="'faq'" [showHeader]="false">
    <div class="row">
        <div class="col-12">
            <div class="swiper-container mySwiper">
                <div class="swiper-wrapper">
                    <ng-container *ngFor="let imgdata of img; let index = index">
                        <div class="swiper-slide">
                            <img [id]="'img'+index" [src]="imgdata" alt="" class="w-100">
                        </div>
                    </ng-container>
                </div>
                <div class="swiper-button-next"></div>
                <div class="swiper-button-prev"></div>
                <div class="swiper-pagination"></div>
            </div>
        </div>
    </div>
    <img class="icon-close" [src]="iconClose" alt="icon-close" (click)="onClose()">
</p-dialog>