<p-dialog header="คำถามที่พบบ่อย" [(visible)]="openPopup" [modal]="true" [style]="{width: '60vw'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false" (onHide)="onClose()" [styleClass]="'faq'" [showHeader]="false">
    <img class="icon-close" [src]="iconClose" alt="icon-close" (click)="onClose()">
    <p class="text-center head-text">คำถามที่พบบ่อย</p>
    <p *ngIf="faq && faq.length === 0" class="text-center" style="font-size: 18px;">ไม่มีข้อมูล</p>
    <div class="accordion" id="accordionExample">
        <div class="card" *ngFor="let dataFaq of faq; let index = index">
            <div class="title">
                <button [id]="'btn-collapse'+index" class="btn p-0 w-100 d-flex" type="button" data-toggle="collapse" [attr.data-target]="'#collapse'+index" aria-expanded="false" aria-controls="collapseOne" (click)="resetClass('collapse'+index)">
                    <div class="run-number"><span>{{ index + 1 }}</span></div>
                    <div class="text" style="white-space: break-spaces;">
                        <p>{{ dataFaq.Question }}</p>
                    </div>
                </button>
            </div>
            <div [id]="'collapse'+index" class="collapse" [ngClass]="{'in': blockIn}" aria-labelledby="headingOne" data-parent="#accordionExample">
                <div class="card-body">
                    {{ dataFaq.Answer }}
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="text-center btn-operator">
        <button class="btn btn-custom" (click)="openChat()">
            <img [src]="iconChat" alt="">
            แชทสอบถามข้อมูล
        </button>
    </div> -->
    <!-- <div class="operator">
        <p>ติดต่อเจ้าของบูธ</p>
        <img href="https://page.line.me/211topar" class="icon-operator" [src]="iconOperator" alt="">
    </div> -->
</p-dialog>