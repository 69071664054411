<div class="box-chat">
    <p (click)="chats(chat)"><img src="../../../../assets/images/icon/ms.png" alt=""> {{header}}</p>
    <ng-container *ngIf="chat">
        <p class="float-right" style="position: relative;
    top: -22px;
    right: 12px;" (click)="chats(chat)">X</p>
        <hr>
        <div class="box-message " [ngStyle]="{'height.px':height}">
            <div class="row" [formGroup]="formMessage" *ngIf="!app.Authorization">
                <div class="col-12">
                    <p>ชื่อแสดงในแชท</p>
                    <input type="text" class="form-control" formControlName="CustomerName">
                    <br>
                    <p>เบอร์โทรศัพท์</p>
                    <input type="text" class="form-control" formControlName="PhoneNo">
                    <br>
                    <button class="btn btn-primary btn-block" (click)="login()">ยืนยัน</button>
                </div>
            </div>
            <div class="row" *ngIf="itemChat">
                <div class="col-12" *ngFor="let chat of itemChat.Replies; let i = index"
                    [ngClass]="{'me':chat.ReplyMemberId > 0, 'he':chat.ReplyMemberId == 0}">
                    <ng-container *ngIf="i==0">
                        <p>
                            {{chat.ReplyMemberName}}
                        </p>
                    </ng-container>
                    <ng-container *ngIf="i > 0">
                        <p *ngIf="itemChat.Replies[i-1].ReplyMemberId != chat.ReplyMemberId ">
                            {{chat.ReplyMemberName}}
                        </p>
                    </ng-container>
                    <span [ngClass]="{'text-bk':chat.ReplyMessageText.length > 24}">
                        {{chat.ReplyMessageText || 0}}
                        <p> {{chat.CreatedAt | date: 'h:mm'}} น.</p>
                    </span>
                    <br>
                </div>
            </div>
        </div>
        <div class="box-send-message" *ngIf="app.Authorization">
            <hr>
            <div class="row">
                <div class="col-8">
                    <input type="text" class="form-control" #message>
                </div>
                <div class="col-4">
                    <button class="btn btn-primary btn-block" (click)="sendMessage(message)">ส่ง</button>
                </div>
            </div>
        </div>
    </ng-container>
</div>