<br><br><br>
<br><br><br>
<div class="container">
    <ng-container *ngFor="let live of itemsLive;let i = index">
        <div class="row">
            <div class="col-12 text-center" *ngIf="i==0">
                <h3>{{live.LiveStreamTitle}}</h3>
                <br>
                <div id="twitch-embed1" *ngIf="!live.UsePlayBack && i == 0"></div>
                <div id="twitch-embed2" *ngIf="!live.UsePlayBack && i == 1"></div>
                <div id="twitch-embed3" *ngIf="!live.UsePlayBack && i == 2"></div>
                <div *ngIf="live.UsePlayBack" [innerHTML]="live.VideoPlayBackLink"></div>
            </div>
        </div>
        <br>
    </ng-container>
    <div class="row">
        <div class="col-12 text-center">
            <button class="btn btn-warning" (click)="backurl()">ย้อนกลับ</button>
        </div>
    </div>
    <br><br>
</div>


<!-- <div style="position: relative; padding-top: 56.25%;"><iframe
        src="https://iframe.videodelivery.net/489cd94c376c1dfb878159d67cc85485?loop=true&autoplay=true"
        style="border: none; position: absolute; top: 0; height: 100%; width: 100%; left:0px; right: 0px;"
        allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
        allowfullscreen="true"></iframe></div> -->